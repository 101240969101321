import * as React from "react";
import {useIntl} from "gatsby-plugin-intl";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

import HeroSection from "../components/Home/HeroSection";
import GlobalDeliveryNumbersSection from "../components/Home/GlobalDeliveryNumbersSection";
import Model360Section from "../components/Home/Model360Section";
import TourSection from "../components/Home/TourSection";
import SmartModelSection from "../components/Home/SmartModelSection";
import WhyOurProductsSection from "../components/Home/WhyOurProductsSection";
import OurClientsSection from "../components/Reusable/OurClientsSections";
import OurPartnerSection from "../components/Reusable/OurPartnersSection";
import ContactSection from "../components/Home/ContactSection";
import Footer from "../components/Reusable/Footer";

const IndexPage = () => {
  const intl = useIntl();

  return (
    <>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({id: "headHome.title"})}
        description={intl.formatMessage({id: "headHome.description"})}
      />
      <Layout intl={intl}>
        <HeroSection intl={intl} />
        <GlobalDeliveryNumbersSection intl={intl} />
        <SmartModelSection intl={intl} />
        <TourSection intl={intl} />
        <Model360Section intl={intl} />
        <WhyOurProductsSection intl={intl} />
        <OurClientsSection intl={intl} />
        <OurPartnerSection intl={intl} />
        <ContactSection intl={intl} />
        <Footer intl={intl} />
      </Layout>
    </>
  );
};

export default IndexPage;
