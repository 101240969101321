import React, { useEffect, useState } from "react";
import './Navigation.scss'
import { useScrollPosition } from "../react-hooks/useScrollPosition";
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

const Navigation = ({ intl, isMobile, funds }) => {
  const [backgroundLight, setBackgroundLight] = useState(true);
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      setIsFirefox(true);
    }
  }, [])

  useScrollPosition(({ prevPos, currPos }) => {
    const isInterior = window.location.pathname.includes('interior');
    const isExterior = window.location.pathname.includes('interactive');
    const isOther = window.location.pathname.includes('privacy-policy') || window.location.pathname.includes('terms');
    const isFireFox = window.navigator.userAgent.indexOf("Firefox") !== -1;
    const SIZE_L = window.innerWidth < 1604;
    const SIZE_STOP = window.innerWidth <= 1332;

    if (isFireFox || isFirefox || isOther) {
      return setBackgroundLight(true);
    }

    if (SIZE_STOP) {
      return setBackgroundLight(true);
    }

    if (isInterior) {
      if (currPos.y < (SIZE_L ? -740 : -800) && currPos.y > -1480) {
        setBackgroundLight(true);
      } else if (currPos.y < (SIZE_L ? -8080 : -8160) && currPos.y > (SIZE_L ? -10060 : -10100)) {
        setBackgroundLight(true);
      } else if (currPos.y < (SIZE_L ? -11960 : -12140)) {
        setBackgroundLight(true);
      } else {
        setBackgroundLight(false);
      }
    } else if (isExterior) {
      if (currPos.y < (SIZE_L ? -680 : -800) && currPos.y > (SIZE_L ? -1300 : -1480)) {
        setBackgroundLight(true);
      } else if (currPos.y < (SIZE_L ? -6600 : -6900) && currPos.y > (SIZE_L ? -8520 : -8820)) {
        setBackgroundLight(true);
      } else if (currPos.y < (SIZE_L ? -10500 : -10880)) {
        setBackgroundLight(true);
      } else {
        setBackgroundLight(false);
      }
    } else {
      if (currPos.y < (SIZE_L ? -680 : -800) && currPos.y > -1480) {
        setBackgroundLight(true);
      } else if (currPos.y < -6790) {
        setBackgroundLight(true);
      } else {
        setBackgroundLight(false);
      }
    }
  }, [])

  return (
    <>
      <nav className={`_3de-navigation-wrapper ${backgroundLight ? 'light-bg' : 'dark-bg'}`}>
        {(isMobile === false) && <NavDesktop funds={funds} intl={intl}/>}
        {(isMobile) && <NavMobile funds={funds} intl={intl}/>}
      </nav>
    </>
  )
}

export default Navigation;
