import React, { useEffect, useState } from "react";
import './FrameWrapper.scss'
import PlaceholderInteriorTour from '../../images/placeholders/tour-interior-preview-btn-small.png';
import PlaceholderTour from '../../images/placeholders/tour-preview-btn-small.png';
import PlaceholderModel360 from '../../images/placeholders/ttdh-preview-btn-small.png';
import PlaceholderSmartModel from '../../images/placeholders/smart-model-preview-btn-small.png';
import { handlerTracking } from "../../helpers/ga";

const FrameWrapper = ({src, title, type, initialHide, interiorTour, intl}) => {
  const [showIframe, setShowIframe] = useState(false);
  const [initHidePassed, setInitHidePassed] = useState(initialHide);
  const [showIframeType, setShowIframeType] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) {
      setIsMobile(true)
    }
  }, [])

  const handlerOpenIframe = (passedType, passedSrc) => {
    if (isMobile) {
      const withRemovedIsTourParameter = (passedType === 'ttdh') ? passedSrc.slice(0, - 12) : passedSrc;

      handlerTracking('Preview Button','Click', `Mobile - Show preview for - ${passedType}`)
      return window.open(withRemovedIsTourParameter, '_blank')
    }
    handlerTracking('Preview Button','Click', `Show preview for - ${passedType}`)

    setInitHidePassed(false);
    setShowIframe(true);
    setShowIframeType(passedType);
  }

  useEffect(() => {
    if (!initHidePassed || !initialHide) {
      handlerOpenIframe(type, src)
    }
  }, [initHidePassed, initialHide])

  const setProperImageUrl = (url) => {
    if (url === 'tour' &&  interiorTour) {
      return PlaceholderInteriorTour
    }

    switch (url) {
      case 'tour':
        return PlaceholderTour
      case 'ttdh':
        return PlaceholderModel360
      case 'smart-model':
        return PlaceholderSmartModel
      default:
        return 'https://via.placeholder.com/200'
    }
  }

  return (
    <div className={`iframe-wrapper container-${type}`}>
      {((showIframe && showIframeType === type) && !initHidePassed ) ? (
        <iframe src={src} frameBorder="0" className={`iframe-wrapper__${type}`} allowFullScreen title={title} />
      ) : (
        <div className={`iframe-wrapper__placeholder iframe-wrapper__placeholder-${type}`}>
          <img src={setProperImageUrl(type)} alt="placeholder"/>
          <button type="button" onClick={() => handlerOpenIframe(type, src)}>{intl.formatMessage({ id: 'homePage.extra.clickToSee' })}</button>
        </div>
      )}
    </div>
  )
}

export default FrameWrapper;
