import React from "react";
import "./SmartModelSection.scss";
import FrameWrapper from "../Reusable/FrameWrapper";
import {Link} from "gatsby-plugin-intl";
import {handlerTracking} from "../../helpers/ga";

const SmartModelSection = ({intl}) => {
  const smartMakietaStandardLink = {
    pl: "https://standard.3destate.app/",
    en: "https://standard-en.3destate.app/",
    de: "https://strona-glowna.3destate.app/",
  };

  return (
    <section className="_3de-smart-model-section">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-model-360-section__title">
          {intl.formatMessage({id: "homePage.discoverTitle"})}
        </h2>
        <div className="_3de-smart-model-section__smart-model-content">
          <div className="left-column">
            <div className="_3de-smart-model-section__smart-model-content-informations">
              <div className="table-left">
                <h3>
                  {intl.formatMessage({id: "homePage.discoverSmartModelTitle"})}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "homePage.discoverSmartModelDescription",
                  })}
                </p>
              </div>
              <div className="tablet-right">
                <ul>
                  <li>
                    {intl.formatMessage({
                      id: "homePage.discoverSmartModelListFirst",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "homePage.discoverSmartModelListSecond",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "homePage.discoverSmartModelListThird",
                    })}
                  </li>
                  <li>
                    {intl.formatMessage({
                      id: "homePage.discoverSmartModelListForth",
                    })}
                  </li>
                </ul>
                <Link
                  to="/interactive-mockup"
                  className="btn_3destate"
                  onClick={() =>
                    handlerTracking(
                      "About Product Button",
                      "Click",
                      "Open Exterior #SmartModel"
                    )
                  }
                >
                  {intl.formatMessage({id: "homePage.extra.knowProductButton"})}
                </Link>
              </div>
            </div>
          </div>
          <div className="right-column">
            <FrameWrapper
              intl={intl}
              src={smartMakietaStandardLink[intl.locale]}
              title="smart-model-example"
              type="smart-model"
              initialHide
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SmartModelSection;
