import React, {useEffect, useState} from "react";
import {changeLocale, Link} from "gatsby-plugin-intl";
import Logo3Destate from "../images/logo-3destate.svg";
import AskAboutButton from "./utility/AskAboutButton";
import {FaFacebookF, FaLinkedinIn, FaInstagram} from "react-icons/fa";
import {handlerTracking} from "../helpers/ga";

const NavMobile = ({intl, funds}) => {
  const [language] = useState(intl.locale);
  const [initialPathName, setInitialPathName] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const body = document.querySelector("body");

  const handlerChangeLanguage = (lang) => {
    changeLocale(lang);
    handlerTracking(
      "Language Button",
      "Click",
      `Mobile - Changed language to ${lang}`
    );
  };

  const handlerToggleMenu = () => {
    if (showMenu) {
      setShowMenu(false);
      body.style.overflow = "auto";
    } else {
      setShowMenu(true);
      body.style.overflow = "hidden";
      handlerTracking("Navigation Button", "Click", `Mobile - Open Menu`);
    }
  };

  useEffect(() => {
    const pathName = window.location.pathname;

    if (
      pathName.includes("interactive-smart-mockup") ||
      pathName.includes("interactive-mockup")
    ) {
      body.style.overflow = "auto";
      setInitialPathName("exterior");
    } else if (pathName.includes("interior")) {
      setInitialPathName("interior");
      body.style.overflow = "auto";
    } else {
      setInitialPathName("home");
      body.style.overflow = "auto";
    }
  }, [body]);

  return (
    <>
      <div className="_3de-navigation__mobile-header">
        <Link
          to="/"
          className="_3de-navigation__list-item--logo"
          onClick={() =>
            handlerTracking(
              "Navigation Link",
              "Click",
              "Mobile - Go to home (LOGO)"
            )
          }
        >
          <img src={Logo3Destate} alt="3destate-logo" />
        </Link>
        <button type="button" onClick={handlerToggleMenu}>
          <span className={showMenu ? "close" : ""} />
        </button>
      </div>
      <div className={`_3de-navigation__mobile ${showMenu ? "show" : ""}`}>
        <ul className="_3de-navigation__mobile-list">
          <li className="_3de-navigation__mobile-list-item">
            <Link
              className={`link ${
                initialPathName === "home" && !funds ? "active" : ""
              }`}
              onClick={() =>
                handlerTracking(
                  "Navigation Link",
                  "Click",
                  "Mobile - Open home page"
                )
              }
              to="/"
            >
              {intl.formatMessage({id: "navigation.homePage"})}
            </Link>
          </li>
          <li className="_3de-navigation__mobile-list-item">
            <Link
              className={`link ${
                initialPathName === "interior" ? "active" : ""
              }`}
              onClick={() =>
                handlerTracking(
                  "Navigation Link",
                  "Click",
                  "Mobile - Open interior page"
                )
              }
              to="/interior"
            >
              {intl.formatMessage({id: "navigation.interiorPage"})}
            </Link>
          </li>
          <li className="_3de-navigation__mobile-list-item">
            <Link
              className={`link ${
                initialPathName === "exterior" ? "active" : ""
              }`}
              onClick={() =>
                handlerTracking(
                  "Navigation Link",
                  "Click",
                  "Mobile - Open exterior page"
                )
              }
              to="/interactive-mockup"
            >
              {intl.formatMessage({id: "navigation.exteriorPage"})}
            </Link>
          </li>
          <li className="_3de-navigation__mobile-list-item">
            <AskAboutButton intl={intl} />
          </li>
        </ul>
        <div className="social-media-wrapper">
          <a
            href="https://www.facebook.com/permalink.php?story_fbid=963557464324846&id=578109989536264"
            className="social-media link fb"
            target="_blank"
            onClick={() =>
              handlerTracking(
                "Navigation Link",
                "Click",
                "Mobile - Open Facebook"
              )
            }
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.linkedin.com/company/3destate-usa/"
            className="social-media link linkedin"
            target="_blank"
            onClick={() =>
              handlerTracking(
                "Navigation Link",
                "Click",
                "Mobile - Open Linkedin"
              )
            }
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://www.instagram.com/3destate_usa/"
            className="social-media link linkedin"
            target="_blank"
            onClick={() =>
              handlerTracking(
                "Navigation Link",
                "Click",
                "Mobile - Open Linkedin"
              )
            }
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </>
  );
};

export default NavMobile;
