import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultMetaImage from '../../static/images/meta-image-3destate.jpg'

function Seo({ description, lang, meta, title, customLink }) {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    image
                }
            }
        }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const url = typeof window !== 'undefined' ? window.location.href : 'undefined';

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      <meta name="description" content={metaDescription} />
      <meta name="image" content={defaultMetaImage} />
      <meta name="google-site-verification" content="zimyAJd0rL1tJJ2Zjk8dBx1b1JDBrzWxsRSaKLgx2yU" />

      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={defaultMetaImage} />
      <meta property="fb:app_id" content="101689081475413" />

      <meta charSet="utf-8" />
      {customLink && (
        <link
          href="https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/css/main.css"
          rel="stylesheet" />
      )}
    </Helmet>
  )
}

export default Seo
