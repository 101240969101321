import React from "react";
import "./WhyOurProductsSection.scss";
import Card1 from "../../images/components/home/card-1.svg";
import Card2 from "../../images/components/home/card-2.svg";
import Card3 from "../../images/components/home/card-3.svg";
import Card4 from "../../images/components/home/card-4.svg";
import Card5 from "../../images/components/home/card-5.svg";
import Card6 from "../../images/components/home/card-6.svg";

const WhyOurProductsSection = ({intl}) => (
  <section className="_3de-why-our-products-section">
    <div className="_3de-container-wrapper">
      <h2 className="_3de-container-wrapper__title">
        {intl.formatMessage({id: "homePage.whyOurProductTitle"})}
      </h2>
      <div className="_3de-why-our-products-section__cards">
        <div className="cards_img">
          <img src={Card1} alt="card" />
          <h3>
            {intl.formatMessage({id: "homePage.whyOurProductCard1.title"})}
          </h3>
          <p>
            {intl.formatMessage({
              id: "homePage.whyOurProductCard1.description",
            })}
          </p>
        </div>
        <div className="cards_img">
          <img src={Card2} alt="card" />
          <h3>
            {intl.formatMessage({id: "homePage.whyOurProductCard2.title"})}
          </h3>
          <p>
            {intl.formatMessage({
              id: "homePage.whyOurProductCard2.description",
            })}
          </p>
        </div>
        <div className="cards_img">
          <img src={Card3} alt="cards" />
          <h3>
            {intl.formatMessage({id: "homePage.whyOurProductCard3.title"})}
          </h3>
          <p>
            {intl.formatMessage({
              id: "homePage.whyOurProductCard3.description",
            })}
          </p>
        </div>
        <div className="cards_img">
          <img src={Card4} alt="card" />
          <h3>
            {intl.formatMessage({id: "homePage.whyOurProductCard4.title"})}
          </h3>
          <p>
            {intl.formatMessage({
              id: "homePage.whyOurProductCard4.description",
            })}
          </p>
        </div>
        <div className="cards_img">
          <img src={Card5} alt="card" />
          <h3>
            {intl.formatMessage({id: "homePage.whyOurProductCard5.title"})}
          </h3>
          <p>
            {intl.formatMessage({
              id: "homePage.whyOurProductCard5.description",
            })}
          </p>
        </div>
        <div className="cards_img">
          <img src={Card6} alt="card" />
          <h3>
            {intl.formatMessage({id: "homePage.whyOurProductCard6.title"})}
          </h3>
          <p>
            {intl.formatMessage({
              id: "homePage.whyOurProductCard6.description",
            })}
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default WhyOurProductsSection;
