import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import '../components/InformationPrivacyPolicy.scss';

const InformationPrivacyPolicy = () => {
  const intl = useIntl();

  return (
    <section className="_3de-privacy-policy-information-section">
      <div className="_3de-container-wrapper">
        <div className="_3de-privacy-policy-information-section__content">
          <p className="_3de-privacy-policy-information-section__content-text">
            {intl.formatMessage({ id: 'privacyPolicyPage.text1' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text2' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text3' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text4' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text5' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text6' })}
            <br/>
          </p>
          <ul className="_3de-privacy-policy-information-section__content-bullets">
            <li>{intl.formatMessage({ id: 'privacyPolicyPage.unorderedList.list1' })}</li>
            <li>{intl.formatMessage({ id: 'privacyPolicyPage.unorderedList.list2' })}</li>
            <li>{intl.formatMessage({ id: 'privacyPolicyPage.unorderedList.list3' })}</li>
          </ul>
          <p className="_3de-privacy-policy-information-section__content-text">
            {intl.formatMessage({ id: 'privacyPolicyPage.text7' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text8' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text9' })}
            <br/><br/>
            Cookies
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text10' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text11' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text12' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text13' })}
            <br/><br/>
            {intl.formatMessage({ id: 'privacyPolicyPage.text14' })}
          </p>
        </div>
      </div>
    </section>
  )
}

export default InformationPrivacyPolicy;
