import React from "react";
import "./Footer.scss";
import {Link} from "gatsby-plugin-intl";
import {handlerTracking} from "../../helpers/ga";
import {StaticImage} from "gatsby-plugin-image";
import phone from "../../images/assets/styles/template-3dstate_files/telefon.png";
import logo_costerion from "../../images/assets/images/costerion_logo.png";
import location from "../../images/assets/styles/template-3dstate_files/lokalizacja-1.png";
import mail from "../../images/assets/styles/template-3dstate_files/mail.png";
const Footer = ({intl}) => (
  <article>
    <section
      className="fw-section layout-boxed without-overlay without-parallax vc_custom_1527181352184"
      data-offset-top="180"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="vc_empty_space" style={{height: "25px"}}>
              <span className="vc_empty_space_inner"></span>
            </div>
            <div className="text-block">
              <h5 style={{textAlign: "center", color: "grey"}}>
                3D Estate USA created by:
              </h5>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-sm-3 col-sm-offset-0 text-center col-xs-6 vc_custom_1527856402290 ">
                <a href="http://costerion.com/">
                  <img
                    src={logo_costerion}
                    alt="card"
                    className="img-fluid"
                    style={{maxWidth: "75%"}}
                    width="481"
                    height="343"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="kontakt"
      className="fw-section layout-boxed without-overlay without-parallax vc_custom_1527182537383"
      data-offset-top="180"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-2"></div>
          <div className="col-sm-8">
            <div className="vc_empty_space" style={{height: "50px"}}>
              <span className="vc_empty_space_inner"></span>
            </div>
            <div className="row">
              <div className="col-sm-4 vc_custom_1527546926094">
                <div className="text-block">
                  <p>
                    <img
                      className="aligncenter wp-image-1274"
                      src={phone}
                      alt=""
                      sizes="(max-width: 56px) 100vw, 56px"
                      width="56"
                      height="56"
                    />
                  </p>
                  <h5 style={{textAlign: "center", color: "grey !important"}}>
                    <a
                      className="email text-decoration-none"
                      href="tel:7188105358"
                    >
                      (718) 810-5358
                    </a>
                  </h5>
                </div>
              </div>

              <div className="col-sm-4 vc_custom_1527546948634">
                <div className="text-block">
                  <p>
                    <img
                      className="aligncenter wp-image-1280"
                      src={location}
                      alt=""
                      sizes="(max-width: 56px) 100vw, 56px"
                      width="56"
                      height="56"
                    />
                  </p>
                  <h5 style={{textAlign: "center", color: "grey"}}>
                    1225 Franklin Avenue,
                    <br />
                    Garden City, New York 11530
                  </h5>
                  <h5 style={{textAlign: "center", color: "grey"}}></h5>
                </div>
              </div>
              <div className="col-sm-4 vc_custom_1583312818221">
                <div className="text-block">
                  <p>
                    <img
                      className="aligncenter wp-image-1276"
                      src={mail}
                      alt=""
                      sizes="(max-width: 56px) 100vw, 56px"
                      width="56"
                      height="56"
                    />
                  </p>
                  <h5 style={{textAlign: "center", color: "grey"}}>
                    <a className="email" href="mailto:info@3destateusa.com">
                      info@3destateusa.com
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    </section>
    <section
      id="kontakt2"
      className="fw-section layout-boxed without-overlay without-parallax vc_custom_1527182669115"
      data-offset-top="180"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="vc_empty_space" style={{height: "8px"}}>
              <span className="vc_empty_space_inner"></span>
            </div>
            <div className="text-block vc_custom_1527857456600">
              <hr
                style={{
                  height: "1px",
                  border: "none",
                  color: "#dcdcdc",
                  backgroundColor: "#dcdcdc",
                  opacity: "0.25;",
                }}
              />
              <h4
                style={{textAlign: "center", color: "grey", fontSize: "90%;"}}
              >
                <strong>2021&nbsp;© 3D Estate</strong>
              </h4>
            </div>
            <div className="vc_empty_space" style={{height: "32px"}}>
              <span className="vc_empty_space_inner"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <footer>
      <hr classNameName="custom-hr" />
      <div classNameName="additional-info-rule">
        <p>2021 &#169; 3D Estate</p>
        <Link
          style={{marginRight: "6px"}}
          to="/policy-privacy"
          onClick={() =>
            handlerTracking("Footer Link", "Click", "Open Privacy Policy")
          }
        >
          {intl.formatMessage({id: "homePage.footer.privacyPolicy"})}
        </Link>
      </div>
    </footer> */}
  </article>
);
export default Footer;
