import React from "react";
import Slider from "react-slick";
import "./OurClientsSection.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AskAboutButton from "../utility/AskAboutButton";

import ClientOpinion1 from "../../images/components/home/Daria Małecka.jpeg";
import ClientOpinion2 from "../../images/components/home/Jakub Tryboń.jpeg";
import ClientOpinion3 from "../../images/components/home/client-opinion-1.jpg";
import ClientOpinion4 from "../../images/components/home/Patrycja-Wilińska.png";
import ClientOpinion4Srcset from "../../images/components/home/Patrycja-Wilińska@2x.png";

import Client1 from "../../images/components/home/clients/atal_logo.png";
import Client2 from "../../images/components/home/clients/eurostyl_logo.png";
import Client3 from "../../images/components/home/clients/robyg_logo.png";
import Client4 from "../../images/components/home/clients/skanksa_logo.png";
import Client5 from "../../images/components/home/clients/marvipol_logo.png";
import Client6 from "../../images/components/home/clients/murapol_logo.png";
import Client7 from "../../images/components/home/clients/AGROBEX_logo.png";
import Client8 from "../../images/components/home/clients/apricot_logo.png";
import Client9 from "../../images/components/home/clients/asbud_logo.png";
import Client10 from "../../images/components/home/clients/BPI_logo.png";
import Client11 from "../../images/components/home/clients/JWC_logo.png";
import Client12 from "../../images/components/home/clients/Temar_logo.png";
import Client13 from "../../images/components/home/clients/MTProject_logo.png";
import Client14 from "../../images/components/home/clients/YTT_logo.png";
import Client15 from "../../images/components/home/clients/obido_logo.png";

import Client16 from "../../images/components/home/clients/henniger_logo.png";
import Client17 from "../../images/components/home/clients/taniemieszkania_logo.png";
import Client18 from "../../images/components/home/clients/Ronson_logo.png";
import Client19 from "../../images/components/home/clients/TDJ-estate_logo.png";
import Client20 from "../../images/components/home/clients/yuniversal_logo.png";
import Client21 from "../../images/components/home/clients/targiMieszkaniDomow_logo.png";
import Client22 from "../../images/components/home/clients/SBDiM_logo.png";
import Client23 from "../../images/components/home/clients/KGDiM_logo.png";
import Client24 from "../../images/components/home/clients/volumetric_logo.png";
import Client25 from "../../images/components/home/clients/motaengil_logo.png";
import Client26 from "../../images/components/home/clients/nowyadres.png";
import Client27 from "../../images/components/home/clients/rafin_logo.png";
import Client28 from "../../images/components/home/clients/developres_logo.png";
import Client29 from "../../images/components/home/clients/vantage_logo.png";
import sati from "../../images/assets/images/sati_2.png";
import order from "../../images/assets/images/3D_Estate_Order.pdf";

const desktopSliderOptions = {
  pauseOnHover: true,
  infinite: true,
  autoplay: true,
  slidesToShow: 6,
  slidesToScroll: 6,
};

const mobileSliderOptions = {
  infinite: true,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 3,
};

const firstSliderSettings = {
  ...desktopSliderOptions,
  speed: 2500,
  autoplaySpeed: 6000,
};

const secondSliderSettings = {
  ...desktopSliderOptions,
  speed: 1500,
  autoplaySpeed: 4500,
};

const firstMobileSliderOptions = {
  ...mobileSliderOptions,
  speed: 2500,
  autoplaySpeed: 6000,
};

const secondMobileSliderOptions = {
  ...mobileSliderOptions,
  speed: 1500,
  autoplaySpeed: 4500,
};

const alterSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  dots: true,
  fade: true,
};

const OurClientsSection = ({isMobile, intl}) => {
  const getProperClientImage = (index) => {
    switch (index) {
      case 1:
        return [Client1, Client2];
      case 2:
        return [Client3, Client4];
      case 3:
        return [Client5, Client6];
      case 4:
        return [Client7, Client8];
      case 5:
        return [Client9, Client10];
      case 6:
        return [Client11, Client12];
      case 7:
        return [Client13, Client14];
      case 8:
        return [Client15, Client16];
      case 9:
        return [Client17, Client18];
      case 10:
        return [Client19, Client20];
      case 11:
        return [Client21, Client22];
      case 12:
        return [Client23, Client24];
      case 13:
        return [Client25, Client26];
      case 14:
        return [Client27, Client28];
      default:
        return ["", ""];
    }
  };

  return (
    <article>
      <section className="_3de-our-clients-section" style={{height: "auto"}}>
        <div className="_3de-container-wrapper">
          <h2 className="_3de-container-wrapper__title">
            {intl.formatMessage({id: "homePage.ourClientsTitle1"})}
            <span>{intl.formatMessage({id: "homePage.ourClientsTitle2"})}</span>
            {intl.formatMessage({id: "homePage.ourClientsTitle3"})}
          </h2>
          <div className="first-slider">
            {isMobile && (
              <Slider {...firstMobileSliderOptions}>
                {[1, 2, 3, 4, 5, 6, 7].map((num, index) => (
                  <div key={num}>
                    <img
                      className="client-logo"
                      src={getProperClientImage(num)[0]}
                      alt="client-logo"
                    />
                    <img
                      className="client-logo"
                      src={getProperClientImage(num)[1]}
                      alt="client-logo"
                    />
                  </div>
                ))}
              </Slider>
            )}
            {isMobile === false && (
              <Slider {...firstSliderSettings}>
                {[
                  Client1,
                  Client2,
                  Client3,
                  Client4,
                  Client5,
                  Client6,
                  Client7,
                  Client8,
                  Client9,
                  Client10,
                  Client11,
                  Client12,
                  Client13,
                  Client14,
                  Client15,
                ].map((num, index) => (
                  <div key={num}>
                    <img className="client-logo" src={num} alt="client-logo" />
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="second-slider">
            {isMobile && (
              <Slider {...secondMobileSliderOptions}>
                {[8, 9, 10, 11, 12, 13, 14].map((num, index) => (
                  <div key={num}>
                    <img
                      className="client-logo"
                      src={getProperClientImage(num)[0]}
                      alt="client-logo"
                    />
                    <img
                      className="client-logo"
                      src={getProperClientImage(num)[1]}
                      alt="client-logo"
                    />
                  </div>
                ))}
              </Slider>
            )}
            {isMobile === false && (
              <Slider {...secondSliderSettings}>
                {[
                  Client16,
                  Client17,
                  Client18,
                  Client19,
                  Client20,
                  Client21,
                  Client22,
                  Client23,
                  Client24,
                  Client25,
                  Client26,
                  Client27,
                  Client28,
                  Client29,
                ].map((num, index) => (
                  <div key={num}>
                    <img className="client-logo" src={num} alt="client-logo" />
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
        {/* START */}
        <section
          className="fw-section layout-boxed without-overlay without-parallax vc_custom_1527025602106"
          data-offset-top={180}
        >
          <div className="container ">
            <div className="row ">
              <div className="col-sm-12">
                <div className="vc_empty_space" style={{height: 48}}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="text-block mb-5">
                  <h1
                    className="mb-4"
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "410%",
                    }}
                  >
                    the best offer for real estate developers
                  </h1>
                  {/* <h3
                    className="slab"
                    style={{textAlign: "center", color: "grey"}}
                  >
                    monthly subscription covers all costs, including development
                    and hosting
                  </h3> */}
                                        <div className="col-12 d-flex justify-content-center buttonSection px-0">
                        <AskAboutButton intl={intl} />
                      </div>
                </div>
                <div className="vc_empty_space" style={{height: 12}}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="vc_empty_space" style={{height: 12}}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="row">
                  <div className="col-md-4 text-center">
                    <h4 className="text-white font-italic mt-4 mt-md-0">
                      FOR INDIVIDUALS:
                    </h4>
                    <div className="text-block greyBorder">
                      <h1
                        className="mb-0"
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "280%",
                        }}
                      >
                        $ 299
                      </h1>
                      <p className="netto" style={{textAlign: "center"}}>
                        <span
                          style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "115%",
                          }}
                        >
                          APARTMENT
                        </span>
                      </p>
                      <p
                        className=""
                        style={{
                          textAlign: "center",
                          color: "grey",
                          fontSize: "165%",
                        }}
                      >
                        3D Virtual Tour
                      </p>
                      <div className="text-center">
                        <a
                          href="https://buy.stripe.com/aEUcPz4Pw7cu3eg8wx"
                          target="_blank"
                          className="btn_3destate btn-ghost ask-about-offer"
                          style={{marginRight: "0px"}}
                        >
                          <span>BUY NOW</span>
                          <div className="liquid"></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 text-center order-md-2 order-3 satisfaction pb-4 pb-md-0 d-flex  justify-content-center align-items-center imgPrice">

                        <img
                          className="img-fluid tokenPrice"
                          alt="Odnośnik link zewnetrzny ikona"
                          src={sati}
                        />

                  </div>
                  <div className="col-md-4 order-md-3 mt-2 mt-md-0 order-2 text-center">
                    <h4 className="text-white font-italic d-none d-md-block">
                      FOR INDIVIDUALS:
                    </h4>
                    <div className="text-block greyBorder">
                      <h1
                        className="mb-0"
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "280%",
                        }}
                      >
                        $ 399
                      </h1>
                      <p className="netto" style={{textAlign: "center"}}>
                        <span
                          style={{
                            textAlign: "center",
                            color: "grey",
                            fontSize: "115%",
                          }}
                        >
                          RESIDENCE
                        </span>
                      </p>
                      <p
                        className=""
                        style={{
                          textAlign: "center",
                          color: "grey",
                          fontSize: "165%",
                        }}
                      >
                        3D Virtual Tour
                      </p>
                      <div className="text-center ">
                        <a
                          href="https://buy.stripe.com/bIY3eZchY0O6cOQ5km"
                          target="_blank"
                          className="btn_3destate btn-ghost ask-about-offer"
                          style={{marginRight: "0px"}}
                        >
                          <span>BUY NOW</span>
                          <div className="liquid"></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 d-flex justify-content-center align-items-center text-block satisText pb-md-0 pb-5">
                    <h1
                      className=""
                      style={{
                        textAlign: "center",
                        color: "#02a0ee",
                        fontSize: "259%",
                        fontStyle: "italic",
                      }}
                    >
                      satisfaction guaranteed or money back
                    </h1>
                  </div>
                </div>
                <div
                  className="vc_empty_space d-md-block d-none"
                  style={{height: 62}}
                >
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END */}
        <div className="_3de-container-wrapper">
          <div className="third-slider">
            <Slider {...alterSettings}>
              <div className="_3de-our-clients-section__client-opinion">
                <span>”</span>
                <img src={ClientOpinion1} alt="client" />
                <p>
                  {intl.formatMessage({
                    id: "homePage.ourClientsTestimonial.first",
                  })}
                </p>
                <h3>Daria Małecka</h3>
                <p>BPI Real Estate</p>
              </div>
              <div className="_3de-our-clients-section__client-opinion">
                <span>”</span>
                <img src={ClientOpinion2} alt="client" />
                <p>
                  {intl.formatMessage({
                    id: "homePage.ourClientsTestimonial.second",
                  })}
                </p>
                <h3>Jakub Tryboń</h3>
                <p>Tryboń PPI SA</p>
              </div>
              <div className="_3de-our-clients-section__client-opinion">
                <span>”</span>
                <img
                  src={ClientOpinion3}
                  alt="client"
                  style={{padding: "8px"}}
                />
                <p>
                  {intl.formatMessage({
                    id: "homePage.ourClientsTestimonial.third",
                  })}
                </p>
                <h3>Dominika Kipigroch</h3>
                <p>Asbud Group</p>
              </div>
              <div className="_3de-our-clients-section__client-opinion">
                <span>”</span>
                <img
                  src={ClientOpinion4}
                  srcSet={ClientOpinion4Srcset}
                  alt="client"
                />
                <p className="_2px-smaller">
                  {intl.formatMessage({
                    id: "homePage.ourClientsTestimonial.forth",
                  })}
                </p>
                <h3>Patrycja Wilińska</h3>
                <p>Robyg Gdańsk</p>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </article>
  );
};

export default OurClientsSection;
