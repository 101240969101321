import React from "react";
import './VisitAnalyticsSection.scss'
import VisitAnalyticsImage from '../../images/components/interior/visit-analytics.png'

const VisitAnalyticsSection = ({intl}) => {
  return (
    <section className="_3de-visit-analytics-section">
      <div className="_3de-container-wrapper">
            <div className="_3de-visit-analytics-section__content">
              <div className="left-column">
                <h2 className="_3de-visit-analytics-section__title">{intl.formatMessage({ id: 'interiorPage.visitAnalyticsTitle' })}</h2>
                <p className="_3de-visit-analytics-section__description">{intl.formatMessage({ id: 'interiorPage.visitAnalyticsDescription' })}</p>
              </div>
              <div className="right-column">
                <img src={VisitAnalyticsImage} alt="visit-analytics"/>
              </div>
            </div>
      </div>
    </section>
  )
}

export default VisitAnalyticsSection;
