import React from "react";
import './AdvantagesSection.scss';
import AdvantageCard1 from '../../images/components/interior/advantages-icons/plan_icon.svg';
import AdvantageCard2 from '../../images/components/interior/advantages-icons/wide-range_icon.svg';
import AdvantageCard3 from '../../images/components/interior/advantages-icons/sales-help_icon.svg';
import AdvantageCard4 from '../../images/components/interior/advantages-icons/fastest-eta_icon.svg';

const AdvantagesSection = ({intl}) => {
  return (
    <section className="_3de-advantages-section">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-advantages-section__title">{intl.formatMessage({ id: 'interiorPage.advantageTitle' })}</h2>
        <div className="_3de-advantages-section__advantage-points">
          <div className="advantage-points__point">
            <img src={AdvantageCard1} alt=""/>
            <h3>{intl.formatMessage({ id: 'interiorPage.advantageCard1.title' })}</h3>
            <p>{intl.formatMessage({ id: 'interiorPage.advantageCard1.description' })}</p>
          </div>
          <div className="advantage-points__point">
            <img src={AdvantageCard2} alt=""/>
            <h3>{intl.formatMessage({ id: 'interiorPage.advantageCard2.title' })}</h3>
            <p>{intl.formatMessage({ id: 'interiorPage.advantageCard2.description' })}</p>
          </div>
          <div className="advantage-points__point">
            <img src={AdvantageCard3} alt=""/>
            <h3>{intl.formatMessage({ id: 'interiorPage.advantageCard3.title' })}</h3>
            <p>{intl.formatMessage({ id: 'interiorPage.advantageCard3.description' })}</p>
          </div>
          <div className="advantage-points__point">
            <img src={AdvantageCard4} alt=""/>
            <h3>{intl.formatMessage({ id: 'interiorPage.advantageCard4.title' })}</h3>
            <p>{intl.formatMessage({ id: 'interiorPage.advantageCard4.description' })}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AdvantagesSection;
