import React, {useState} from "react";
import "./ImplementationExampleSection.scss";
import CardExample1 from "../../images/components/interior/card-example1.png";
import CardExample2 from "../../images/components/interior/card-example2.png";
import CardExample3 from "../../images/components/interior/card-example3.png";
import CardExample4 from "../../images/components/interior/card-example4.png";
import {BsHeart} from "react-icons/bs";
import {handlerTracking} from "../../helpers/ga";

const ImplementationExampleSection = ({intl}) => {
  const [turnOnIframe, setTurnOnIframe] = useState(false);
  const [iframeSource, setIframeSource] = useState("");

  const handlerToggleIframe = (src, type) => {
    if (turnOnIframe) {
      setIframeSource("");
    } else {
      setIframeSource(src);
      handlerTracking(
        "Interior Implementation Button",
        "Click",
        `Show ${type} frame example`
      );
    }

    setTurnOnIframe(!turnOnIframe);
  };

  return (
    <section className="_3de-implementation-example-section">
      {turnOnIframe && iframeSource && (
        <div className="iframe-example-backdrop">
          <div className="iframe-example-wrapper">
            <iframe
              src={iframeSource}
              frameBorder="0"
              title="tour-example"
              width="80%"
              height="70%"
            />
            <button
              type="button"
              onClick={handlerToggleIframe}
              className="iframe-example-wrapper__close-button"
            >
              X
            </button>
          </div>
          <button
            type="button"
            onClick={handlerToggleIframe}
            className="iframe-example-backdrop__overlay-close"
            aria-label="Close"
          />
        </div>
      )}
      <div className="_3de-container-wrapper">
        <h2 className="_3de-implementation-example-section__title">
          {intl.formatMessage({id: "interiorPage.implementationExampleTitle"})}
        </h2>
        <h3 className="_3de-implementation-example-section__description">
          {intl.formatMessage({
            id: "interiorPage.implementationExampleTitleDescriptionList.title",
          })}
        </h3>
        <div className="_3de-implementation-example-section__custom-table">
          <div className="custom-table__header">
            <ul>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableFlatNumber",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableSQM",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableFloor",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableRoomsNumber",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableDownload",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableShowIn3D",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionList.tableTour",
                })}
              </li>
            </ul>
          </div>
          <div className="custom-table__body">
            <ul>
              <li>1.A.2.1</li>
              <li>40,77</li>
              <li>2</li>
              <li>2</li>
              <li>
                <a
                  href="https://images.3destate.pl/floorplantoursfiles/f68c659c-0af7-4773-b02f-bafe7fc6341b_20210325030817007_render_f68c659c-0af7-4773-b02f-bafe7fc6341b_MiniMapRenderLQ_0.png"
                  rel="noreferrer"
                  target="_blank"
                  download
                  onClick={() =>
                    handlerTracking(
                      "Interior Implementation Button",
                      "Click",
                      "Download Button (table)"
                    )
                  }
                >
                  {intl.formatMessage({
                    id: "interiorPage.implementationExampleTitleDescriptionList.downloadButton",
                  })}
                </a>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://360.3destate.pl/website/oslo?lang=en&hideDarkModeButton=true`,
                      "Model360"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-model-pl@2x.png"
                    alt="model-360-btn"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://tours.3destate.pl/website/oslo.3d?lang=en`,
                      "Tour"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-pl@2x.png"
                    alt="virtual-tour-btn"
                  />
                </button>
              </li>
            </ul>
            <ul>
              <li>1.A.2.2</li>
              <li>67,40</li>
              <li>2</li>
              <li>3</li>
              <li>
                <a
                  href="https://images.3destate.pl/floorplantoursfiles/e8390edf-8d58-4016-9e1d-dfde4c053174_20210325003255950_render_e8390edf-8d58-4016-9e1d-dfde4c053174_MiniMapRenderLQ_0.png"
                  rel="noreferrer"
                  target="_blank"
                  download
                  onClick={() =>
                    handlerTracking(
                      "Interior Implementation Button",
                      "Click",
                      "Download Button (table)"
                    )
                  }
                >
                  {intl.formatMessage({
                    id: "interiorPage.implementationExampleTitleDescriptionList.downloadButton",
                  })}
                </a>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://360.3destate.pl/website/berlin?lang=en&hideDarkModeButton=true`,
                      "Model360"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-model-pl@2x.png"
                    alt="model-360-btn"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://tours.3destate.pl/website/berlin.3d?lang=en`,
                      "Tour"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-pl@2x.png"
                    alt="virtual-tour-btn"
                  />
                </button>
              </li>
            </ul>
            <ul>
              <li>1.A.2.3</li>
              <li>83,90</li>
              <li>2</li>
              <li>4</li>
              <li>
                <a
                  href="https://images.3destate.pl/floorplantoursfiles/cbd1af9d-9c12-4342-80f5-5f2f601c4232_20210325043928690_render_cbd1af9d-9c12-4342-80f5-5f2f601c4232_MiniMapRenderLQ_0.png"
                  rel="noreferrer"
                  target="_blank"
                  download
                  onClick={() =>
                    handlerTracking(
                      "Interior Implementation Button",
                      "Click",
                      "Download Button (table)"
                    )
                  }
                >
                  {intl.formatMessage({
                    id: "interiorPage.implementationExampleTitleDescriptionList.downloadButton",
                  })}
                </a>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://360.3destate.pl/website/londyn?lang=en&hideDarkModeButton=true`,
                      "Model360"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-model-pl@2x.png"
                    alt="model-360-btn"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://tours.3destate.pl/website/londyn.3d?lang=en`,
                      "Tour"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-pl@2x.png"
                    alt="virtual-tour-btn"
                  />
                </button>
              </li>
            </ul>
            <ul>
              <li>1.A.2.5</li>
              <li>59,32</li>
              <li>2</li>
              <li>3</li>
              <li>
                <a
                  href="https://images.3destate.pl/floorplantoursfiles/397756e3-a75e-4b73-ac4a-20d3c291b383_20210325033841401_render_397756e3-a75e-4b73-ac4a-20d3c291b383_MiniMapRenderLQ_0.png"
                  rel="noreferrer"
                  target="_blank"
                  download
                  onClick={() =>
                    handlerTracking(
                      "Interior Implementation Button",
                      "Click",
                      "Download Button (table)"
                    )
                  }
                >
                  {intl.formatMessage({
                    id: "interiorPage.implementationExampleTitleDescriptionList.downloadButton",
                  })}
                </a>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://360.3destate.pl/website/londyn?lang=en&hideDarkModeButton=true`,
                      "Model360"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-model-pl@2x.png"
                    alt="model-360-btn"
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() =>
                    handlerToggleIframe(
                      `https://tours.3destate.pl/website/amsterdam.3d?lang=en`,
                      "Tour"
                    )
                  }
                >
                  <img
                    src="https://implementations-staging.3destate.pl/img/small-pl@2x.png"
                    alt="virtual-tour-btn"
                  />
                </button>
              </li>
            </ul>
          </div>
        </div>
        <h3 className="_3de-implementation-example-section__description">
          {intl.formatMessage({
            id: "interiorPage.implementationExampleTitleDescriptionCards.title",
          })}
        </h3>
        <div className="_3de-implementation-example-section__cards">
          <div className="card">
            <p className="title">1.A.2.1</p>
            <div className="icon">{<BsHeart />}</div>
            <img src={CardExample1} alt="card-example" />
            <div className="info">
              <p>
                <span>
                  40,77 m<sup>2</sup>
                </span>{" "}
                | 2{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.rooms",
                })}{" "}
                | 2{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.floor",
                })}
              </p>
              {/* <h4>410 000zł</h4> */}
              <a
                href={`https://tours.3destate.pl/website/oslo.3d?lang=en`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handlerTracking(
                    "Interior Implementation Button",
                    "Click",
                    "See More (card)"
                  )
                }
              >
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.seeMore",
                })}
              </a>
            </div>
          </div>
          <div className="card">
            <p className="title">1.A.2.2</p>
            <div className="icon">{<BsHeart />}</div>
            <img src={CardExample2} alt="card-example" />
            <div className="info">
              <p>
                <span>
                  67,40 m<sup>2</sup>
                </span>{" "}
                | 3{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.rooms",
                })}{" "}
                | 2{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.floor",
                })}
              </p>
              {/* <h4>720 000zł</h4> */}
              <a
                href={`https://tours.3destate.pl/website/berlin.3d?lang=en`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handlerTracking(
                    "Interior Implementation Button",
                    "Click",
                    "See More (card)"
                  )
                }
              >
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.seeMore",
                })}
              </a>
            </div>
          </div>
          <div className="card">
            <p className="title">1.A.2.3</p>
            <div className="icon">
              <BsHeart />
            </div>
            <img src={CardExample3} alt="card-example" />
            <div className="info">
              <p>
                <span>
                  83,90 m<sup>2</sup>
                </span>{" "}
                | 4{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.rooms",
                })}{" "}
                | 2{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.floor",
                })}
              </p>
              {/* <h4>860 000zł</h4> */}
              <a
                href={`https://tours.3destate.pl/website/londyn.3d?lang=en`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handlerTracking(
                    "Interior Implementation Button",
                    "Click",
                    "See More (card)"
                  )
                }
              >
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.seeMore",
                })}
              </a>
            </div>
          </div>
          <div className="card">
            <p className="title">1.A.2.5</p>
            <div className="icon">
              <BsHeart />
            </div>
            <img src={CardExample4} alt="card-example" />
            <div className="info">
              <p>
                <span>
                  59,32 m<sup>2</sup>
                </span>{" "}
                | 3{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.rooms",
                })}{" "}
                | 2{" "}
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.floor",
                })}
              </p>
              {/* <h4>600 000zł</h4> */}
              <a
                href={`https://tours.3destate.pl/website/amsterdam.3d?lang=en`}
                target="_blank"
                rel="noreferrer"
                onClick={() =>
                  handlerTracking(
                    "Interior Implementation Button",
                    "Click",
                    "See More (card)"
                  )
                }
              >
                {intl.formatMessage({
                  id: "interiorPage.implementationExampleTitleDescriptionCards.seeMore",
                })}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImplementationExampleSection;
