import React from "react";
import {Link} from "gatsby-plugin-intl";
import "./TourSection.scss";
import FrameWrapper from "../Reusable/FrameWrapper";
import {handlerTracking} from "../../helpers/ga";

const TourSection = ({intl}) => (
  <section className="_3de-tour-section">
    <div className="_3de-container-wrapper">
      <div className="_3de-tour-section__tour-content">
        <div className="left-column">
          <FrameWrapper
            intl={intl}
            src={`https://tours.3destate.pl/website-start/3.3d?lang=en`}
            title="tour-example"
            type="tour"
            initialHide
            interiorTour={false}
          />
        </div>
        <div className="right-column">
          <div className="_3de-tour-section__tour-content-informations">
            <div className="left-tablet">
              <h3>{intl.formatMessage({id: "homePage.discoverTourTitle"})}</h3>
              <p>
                {intl.formatMessage({id: "homePage.discoverTourDescription"})}
              </p>
            </div>
            <div className="right-tablet">
              <ul>
                <li>
                  {intl.formatMessage({id: "homePage.discoverTourListFirst"})}
                </li>
                <li>
                  {intl.formatMessage({id: "homePage.discoverTourListSecond"})}
                </li>
                <li>
                  {intl.formatMessage({id: "homePage.discoverTourListThird"})}
                </li>
                <li>
                  {intl.formatMessage({id: "homePage.discoverTourListForth"})}
                </li>
              </ul>
              <Link
                to="/interior"
                className="btn_3destate"
                onClick={() =>
                  handlerTracking(
                    "About Product Button",
                    "Click",
                    "Open Interior #Tour"
                  )
                }
              >
                {intl.formatMessage({id: "homePage.extra.knowProductButton"})}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr className="mobile-hr" />
  </section>
);

export default TourSection;
