import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout";
import Seo from "../components/Seo";

import HeroInteriorSection from "../components/Interior/HeroInteriorSection";
import ShowIn3DSection from "../components/Interior/ShowIn3DSection";
import ManyPresentationFormsSection from "../components/Interior/ManyPresentationFormsSection";
import ImplementationExampleSection from "../components/Interior/ImplementationExampleSection";
import AdvantagesSection from "../components/Interior/AdvantagesSection";
import StyleOptionRichnessSection from "../components/Interior/StyleOptionRichnessSection";
import VisitAnalyticsSection from "../components/Interior/VisitAnalyticsSection";
import EasyToImplementSection from "../components/Interior/EasyToImplementSection";
import FlexibleSubscriptionSection from "../components/Interior/FlexibleSubscriptionSection";
import OurClientsSection from "../components/Reusable/OurClientsSections";
import OurPartnerSection from "../components/Reusable/OurPartnersSection";
import ContactSection from "../components/Home/ContactSection";
import Footer from "../components/Reusable/Footer";

const InteriorPage = () => {
  const intl = useIntl();

  return (
    <>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({ id: "headInterior.title" })}
        description={intl.formatMessage({ id: "headInterior.description" })}
      />
      <Layout intl={intl}>
        <HeroInteriorSection intl={intl}/>
        <ShowIn3DSection intl={intl}/>
        <ManyPresentationFormsSection intl={intl}/>
        <ImplementationExampleSection intl={intl}/>
        <AdvantagesSection intl={intl}/>
        <StyleOptionRichnessSection intl={intl}/>
        <VisitAnalyticsSection intl={intl}/>
        <EasyToImplementSection intl={intl}/>
        <FlexibleSubscriptionSection intl={intl}/>
        <OurClientsSection intl={intl}/>
        <OurPartnerSection intl={intl}/>
        <ContactSection intl={intl}/>
        <Footer intl={intl}/>
      </Layout>
    </>
  )
}

export default InteriorPage;
