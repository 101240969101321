import React from "react";
import "./ShowIn3DSection.scss";
import {StaticImage} from "gatsby-plugin-image";
import {IoCheckmarkSharp} from "react-icons/io5";

const ShowIn3DSection = ({intl}) => {
  return (
    <section className="_3de-show-in-3d-section">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-show-in-3d-section__title">
          {intl.formatMessage({id: "interiorPage.showIn3dTitle"})}
        </h2>
        <div className="_3de-show-in-3d-section__content">
          <div className="cards">
            <div className="card_single">
              <div className="card_single__image">
                <StaticImage
                  src="../../images/components/interior/show-in-3d-card1.png"
                  alt="card"
                  placeholder="blurred"
                />
                <div className="check-mark">
                  <IoCheckmarkSharp />
                </div>
              </div>
              <div className="card_single__text">
                <p>
                  {intl.formatMessage({id: "interiorPage.showIn3dCard1.text1"})}
                  <span>
                    {intl.formatMessage({
                      id: "interiorPage.showIn3dCard1.span",
                    })}
                  </span>
                  {intl.formatMessage({id: "interiorPage.showIn3dCard1.text2"})}
                </p>
              </div>
            </div>
            <div className="card_single">
              <div className="card_single__image">
                <StaticImage
                  src="../../images/components/interior/show-in-3d-card2.png"
                  alt="card"
                  placeholder="blurred"
                />
                <div className="check-mark">
                  <IoCheckmarkSharp />
                </div>
              </div>
              <div className="card_single__text">
                <p>
                  {intl.formatMessage({id: "interiorPage.showIn3dCard2.text1"})}
                  <span>
                    {intl.formatMessage({
                      id: "interiorPage.showIn3dCard2.span",
                    })}
                  </span>
                  {intl.formatMessage({id: "interiorPage.showIn3dCard2.text2"})}
                </p>
              </div>
            </div>
            <div className="card_single">
              <div className="card_single__image">
                <StaticImage
                  src="../../images/components/interior/show-in-3d-card3.png"
                  alt="card"
                  placeholder="blurred"
                />
                <div className="check-mark">
                  <IoCheckmarkSharp />
                </div>
              </div>
              <div className="card_single__text">
                <p>
                  {intl.formatMessage({id: "interiorPage.showIn3dCard3.text1"})}
                  <span>
                    {intl.formatMessage({
                      id: "interiorPage.showIn3dCard3.span",
                    })}
                  </span>
                  {intl.formatMessage({id: "interiorPage.showIn3dCard3.text2"})}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShowIn3DSection;
