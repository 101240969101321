import React from "react";
import '../components/Information.scss';

import Image1 from '../images/subsidy/fundusze.png'
import Image2 from '../images/subsidy/rzeczposoplita-polskapng.png'
import Image3 from '../images/subsidy/slaskie.png'
import Image4 from '../images/subsidy/unia-europejska.png'

const Information = () => (
  <section className="_3de-fund-information-section">
    <div className="_3de-container-wrapper">
      <h2 className="_3de-container-wrapper__title">3D ESTATE SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ</h2>
      <div className="_3de-container-wrapper__content">
        <p>realizuje projekt dofinansowany z Funduszy Europejskich</p>
        <p className="quote">„Znaczące zwiększenie konkurencyjności poprzez wdrożenie innowacji produktowej i
          procesowej na skalę międzynarodową”</p>
        <p>
          Celem projektu jest wzmocnienie konkurencyjności przedsiębiorstwa poprzez wdrożenie nowych technologii co
          będzie
          miało wpływ na rozszerzenie działalności i zdobycie nowych rynków zbytu.
        </p>
        <p>
          Efektem projektu jest rozszerzona oferta firmy oparta o rozbudowane usługi w zakresie tworzenia
          wirtualnych spacerów
          na potrzeby prezentacji mieszkań wystawionych na sprzedaż, tworzenia wirtualnych makiet nieruchomości a
          także
          konfigurator wykończeń nieruchomości do sprzedaży wykończeń online.
        </p>
        <p className="price"> Całkowita wartość projektu: <span>1 137 750,00 PLN</span></p>
        <p className="price">Dofinansowanie projektu z UE: <span>786 250,00 PLN</span></p>
        <ul>
          <li>
            <img src={Image1} alt=""/>
          </li>
          <li>
            <img src={Image2} alt=""/>
          </li>
          <li>
            <img src={Image3} alt=""/>
          </li>
          <li>
            <img src={Image4} alt=""/>
          </li>
        </ul>
      </div>
    </div>
  </section>
)

export default Information;
