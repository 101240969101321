import React from "react";
import "./EasyToImplementSection.scss";
import {IoCheckmarkSharp} from 'react-icons/io5'

const EasyToImplementSection = ({ isMobile, intl }) => {
  return (
    <section className="_3de-easy-to-implement-section">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-easy-to-implement-section__title">{intl.formatMessage({ id: 'interiorPage.easyToImplementTitle' })}</h2>
        <p className="_3de-easy-to-implement-section__description">{intl.formatMessage({ id: 'interiorPage.easyToImplementDescription' })}</p>
        <div className="_3de-easy-to-implement-section__content">
          {!isMobile ? (
            <div className="line">
              <div className="point point-first">
                <div className="point__index">
                  <p>1</p>
                </div>
                <div className="point__text">
                  <p>{intl.formatMessage({ id: 'interiorPage.easyToImplementStage1' })}</p>
                </div>
              </div>
              <div className="point point-second">
                <div className="point__index">
                  <p>2</p>
                </div>
                <div className="point__text">
                  <p>{intl.formatMessage({ id: 'interiorPage.easyToImplementStage2' })}</p>
                </div>
              </div>
              <div className="point point-third">
                <IoCheckmarkSharp />
                <div className="point__index">
                  <p>3</p>
                </div>
                <div className="point__text">
                  <p className="custom-width">{intl.formatMessage({ id: 'interiorPage.easyToImplementStage3' })}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="line-mobile">
              <div className="first-step">
                <p className="first-step__num">1</p>
                <p className="first-step__text">{intl.formatMessage({ id: 'interiorPage.easyToImplementStage1' })}</p>
                <div className="first-step__line"/>
              </div>
              <div className="second-step">
                <p className="second-step__num">2</p>
                <p className="second-step__text">{intl.formatMessage({ id: 'interiorPage.easyToImplementStage2' })}</p>
                <div className="second-step__line"/>
                <div className="second-step__circle">
                  <IoCheckmarkSharp />
                </div>
              </div>
              <div className="third-step">
                <p className="third-step__num">3</p>
                <p className="third-step__text">{intl.formatMessage({ id: 'interiorPage.easyToImplementStage3' })}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default EasyToImplementSection;
