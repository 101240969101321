import React, {useEffect, useState} from "react";
import FrameWrapper from "../Reusable/FrameWrapper";
import {Link} from "react-scroll";
import "./InteriorModel360.scss";

import Example1 from "../../images/components/interior/model360-london.png";
import Example2 from "../../images/components/interior/model360-paris.png";
import Example3 from "../../images/components/interior/model360-oslo.png";
import Example4 from "../../images/components/interior/model360-amsterdam.png";
import Example5 from "../../images/components/interior/model360-berlin.png";
import Example6 from "../../images/components/interior/model360-mediolan.png";
import {handlerTracking} from "../../helpers/ga";

const InteriorModel360 = ({intl}) => {
  const [pickedExampleIndex, setPickedExampleIndex] = useState("1");
  const [pickedExampleType, setPickedExampleType] = useState("1");
  const [initialHide, setInitialHide] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      setIsMobile(true);
    }
  }, []);

  const handlerSeeExample = (index, type, example) => {
    handlerTracking(
      "Example Button",
      "Click",
      `Show example model360 - ${example}`
    );
    if (isMobile) {
      return window.open(
        `https://360.3destate.pl/przyklady-model-360/${type}?lang=en&isTour=true`,
        "_blank"
      );
    }

    setPickedExampleIndex(index);
    setPickedExampleType(type);
    setInitialHide(false);
  };

  return (
    <div className="_3de-model-360-section__model360-content interior-model-360">
      <div className="left-column">
        <div className="_3de-model-360-section__model360-content-informations">
          <div className="left-tablet">
            <h3>
              {intl.formatMessage({
                id: "interiorPage.presentationFormModel360Title",
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: "interiorPage.presentationFormModel360Description",
              })}
            </p>
          </div>
          <div className="right-tablet">
            <ul>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormModel360Features.feature1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormModel360Features.feature2",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormModel360Features.feature3",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormModel360Features.feature4",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormModel360Features.feature5",
                })}
              </li>
            </ul>
            <Link
              to="contact"
              offset={-20}
              smooth={true}
              duration={800}
              className="btn_3destate z-2"
              onClick={() => handlerTracking("CTA", "Click", "Ask about offer")}
            >
              {intl.formatMessage({id: "navigation.askAboutOfferButton"})}
            </Link>
          </div>
        </div>
      </div>
      <div className="right-column">
        <FrameWrapper
          intl={intl}
          src={`https://360.3destate.pl/przyklady-model-360/${pickedExampleType}?lang=en&isTour=true`}
          title="model-360-example"
          type="ttdh"
          initialHide={initialHide}
        />
        <div className="other-examples">
          <p className="other-examples__text">
            {intl.formatMessage({id: "interiorPage.extra.seeOtherExample"})}
          </p>
          <div className="d-flex">
            {!isMobile && (
              <button
                type="button"
                className={`${pickedExampleIndex === "1" ? "active" : ""}`}
                onClick={() => handlerSeeExample("1", "1", "Example-1")}
              >
                <span className="image-wrapper">
                  <img src={Example1} alt="exmaple-1" />
                </span>
                <p className="description">
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.title",
                  })}
                </p>
              </button>
            )}
            <button
              type="button"
              className={`${pickedExampleIndex === "2" ? "active" : ""}`}
              onClick={() => handlerSeeExample("2", "2", "Example-2")}
            >
              <span className="image-wrapper">
                <img src={Example2} alt="example-2" />
              </span>
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle2.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "3" ? "active" : ""}`}
              onClick={() => handlerSeeExample("3", "3", "Example-3")}
            >
              <span className="image-wrapper">
                <img src={Example3} alt="example-3" />
              </span>
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle1.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "4" ? "active" : ""}`}
              onClick={() => handlerSeeExample("4", "5", "Example-4")}
            >
              <span className="image-wrapper">
                <img src={Example4} alt="example-4" />
              </span>
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle3.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "5" ? "active" : ""}`}
              onClick={() => handlerSeeExample("5", "7", "Example-5")}
            >
              <span className="image-wrapper">
                <img src={Example5} alt="example-4" />
              </span>
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle5.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "6" ? "active" : ""}`}
              onClick={() => handlerSeeExample("6", "mediolan", "Example-6")}
            >
              <span className="image-wrapper">
                <img src={Example6} alt="example-6" />
              </span>
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle6.title",
                })}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteriorModel360;
