import React from "react";
import './HeroSection.scss'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby-plugin-intl";
import { handlerTracking } from "../../helpers/ga";
import Image1 from '../../images/subsidy/fundusze.png'
import Image2 from '../../images/subsidy/rzeczposoplita-polskapng.png'
import Image3 from '../../images/subsidy/slaskie.png'
import Image4 from '../../images/subsidy/unia-europejska.png'


const HeroSection = ({ intl }) => (
  <header className="hero-section">
    <div className="_3de-container-wrapper">
      <div className="left-column">
        <h1 className="title">{intl.formatMessage({ id: "homePage.heroTitle" })}</h1>
        <p className="description">
          {intl.formatMessage({ id: "homePage.heroDescription" })}
          <span>
              {intl.formatMessage({ id: "homePage.heroDescriptionSpan" })}
            </span>
        </p>
      </div>
      <div className="right-column">
        <StaticImage
          src="../../images/components/home/hero-image.png"
          alt="hero-background"
          placeholder="blurred"
          draggable={false}
        />
      </div>
    </div>
    {/*<div className="subsidy-wrapper">*/}
    {/*  <Link to="/funds" className="subsidy-wrapper__redirect"*/}
    {/*        onClick={() => handlerTracking('Funds Button', 'Click', 'Open Funds Page')}>*/}
    {/*    <ul>*/}
    {/*      <li>*/}
    {/*        <img src={Image1} alt=""/>*/}
    {/*      </li>*/}
    {/*      <li>*/}
    {/*        <img src={Image2} alt=""/>*/}
    {/*      </li>*/}
    {/*      <li>*/}
    {/*        <img src={Image3} alt=""/>*/}
    {/*      </li>*/}
    {/*      <li>*/}
    {/*        <img src={Image4} alt=""/>*/}
    {/*      </li>*/}
    {/*    </ul>*/}
    {/*  </Link>*/}
    {/*</div>*/}
  </header>
)

export default HeroSection;
