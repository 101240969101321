import React from "react";
import './HeroInteriorSection.scss';
import { StaticImage } from "gatsby-plugin-image";

const HeroInteriorSection = ({intl}) => (
  <header className="hero-interior-section">
    <div className="_3de-container-wrapper">
      <div className="left-column">
        <h2 className="title">{intl.formatMessage({ id: 'interiorPage.heroTitle' })}</h2>
        <p className="description">{intl.formatMessage({ id: 'interiorPage.heroDescription' })}</p>
      </div>
      <div className="right-column">
        <StaticImage
          src="../../images/components/interior/hero-img-hands.png"
          alt="hero-background"
          placeholder="blurred"
          draggable={false}
        />
      </div>
    </div>
  </header>
)

export default HeroInteriorSection
