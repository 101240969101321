import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout";
import Seo from "../components/Seo";

import ContactSection from "../components/Home/ContactSection";
import Footer from "../components/Reusable/Footer";
import InformationTerms from "../components/InformationTerms";

const PrivacyPolicyPage = () => {
  const intl = useIntl();

  return (
    <>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({ id: "headTerms.title" })}
        description={intl.formatMessage({ id: "headTerms.description" })}
      />
      <Layout intl={intl} funds>
        <InformationTerms intl={intl}/>
        <ContactSection intl={intl}/>
        <Footer intl={intl} />
      </Layout>
    </>
  )
}

export default PrivacyPolicyPage;
