import * as React from "react";
import Navigation from "./Navigation";
import "../style/index.scss";
import {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";


const Layout = ({children, intl, funds}) => {
  const [isMobile, setIsMobile] = useState(null);
  const [seenCookieInformation, setSeenCookieInformation] = useState(true);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("seenCookieInformation"))) {
      setSeenCookieInformation(true);
    } else {
      setSeenCookieInformation(false);
    }
  }, [seenCookieInformation]);

  useEffect(() => {
    if (window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 600) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isMobile]);

  const handlerConfirmSeenCookie = () => {
    localStorage.setItem("seenCookieInformation", "true");
    setSeenCookieInformation(true);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {isMobile})
  );

  return (
    <>
      <Navigation funds={funds} intl={intl} isMobile={isMobile} />
      <main>{childrenWithProps}</main>
      {!seenCookieInformation && (
        <div className="cookie-wrapper">
          <p className="cookie-wrapper__text">
            {intl.formatMessage({id: "cookies"})}{" "}
          </p>
          <button
            type="button"
            aria-label="close button"
            onClick={handlerConfirmSeenCookie}
          >
            <div />
          </button>
        </div>
      )}
    </>
  );
};

export default Layout;
