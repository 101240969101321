import React, {useState} from "react";
import "./FlexibleSubscriptionSection.scss";
import {IoCheckmarkSharp} from "react-icons/io5";
import {Link} from "react-scroll";
import {handlerTracking} from "../../helpers/ga";

const FlexibleSubscriptionSection = ({isMobile, exterior, intl}) => {
  const [activeIndex, setActiveIndex] = useState(1);

  const handlerPrevButton = () => {
    setActiveIndex(activeIndex - 1);
    handlerTracking(
      "Exterior Button",
      "Click",
      `Check Previous Subscription Plan`
    );
  };

  const handlerNextButton = () => {
    setActiveIndex(activeIndex + 1);
    handlerTracking("Exterior Button", "Click", `Check Next Subscription Plan`);
  };

  return (
    <section className="_3de-flexible-subscription-section">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-flexible-subscription-section__title">
          {exterior
            ? `${intl.formatMessage({
                id: "exteriorPage.smartModelPricingTitle",
              })}`
            : `${intl.formatMessage({
                id: "interiorPage.flexibleSubscriptionTitle",
              })}`}
        </h2>
        <p
          className={`_3de-flexible-subscription-section__description ${
            exterior ? "mb-2" : ""
          }`}
        >
          {exterior
            ? `${intl.formatMessage({
                id: "exteriorPage.smartModelPricingDescription",
              })}`
            : `${intl.formatMessage({
                id: "interiorPage.flexibleSubscriptionDescription",
              })}`}
        </p>
        <div className="_3de-flexible-subscription-section__content">
          {!exterior && (
            <div className="offer-costs">
              <div className="prepare prepare-floorplan">
                <h3>$ 0</h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.flexibleSubscriptionPrepare1",
                  })}
                </p>
              </div>
              <div className="prepare prepare-model360">
                <h3>$ 0</h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.flexibleSubscriptionPrepare2",
                  })}
                </p>
              </div>
              <div className="prepare prepare-tour">
                <h3>$ 0</h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.flexibleSubscriptionPrepare3",
                  })}
                </p>
              </div>
            </div>
          )}
          <div
            className={`offer-plans ${
              exterior
                ? `offer-plans__exterior current-active-${activeIndex}`
                : ""
            } `}
          >
            {exterior && (
              <>
                <button
                  className="offer-plans__exterior__button-left"
                  onClick={handlerPrevButton}
                >{`<`}</button>
                <button
                  className="offer-plans__exterior__button-right"
                  onClick={handlerNextButton}
                >{`>`}</button>
              </>
            )}
            <div
              className={`plan plan__basic ${
                exterior ? "plan__exterior" : ""
              } ${activeIndex === 0 ? "active" : ""}`}
            >
              {exterior && intl.locale !== "de" && (
                <h3 className="exterior-title">
                  {intl.formatMessage({
                    id: "exteriorPage.smartModelPricingListTitle.searcher",
                  })}{" "}
                  <span>360</span>
                </h3>
              )}
              {exterior && intl.locale === "de" && (
                <h3 className="exterior-title">
                  <span>Basic</span>
                </h3>
              )}
              {!exterior && <h3>Basic</h3>}
              <hr />
              <ul>
                {exterior ? (
                  <>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.estateModel",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.listView",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.requestSupport",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.allDevices",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.touch4K",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.resources3D",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.integrationCrm",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.easyImplementation",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.compareFlats",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.balconyView",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.investmentArea",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.areaBuildingDetails",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.nightMode",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.localizations",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.video",
                      })}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.floorPlan",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.tour",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.dollhouse",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.model360",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.tripleStyles",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.developerLogo",
                      })}
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div
              className={`plan plan__standard ${
                exterior ? "plan__exterior" : ""
              } ${activeIndex === 1 ? "active" : ""}`}
            >
              {exterior && intl.locale !== "de" && (
                <h3 className="exterior-title">
                  {intl.formatMessage({
                    id: "exteriorPage.smartModelPricingListTitle.smartModel",
                  })}{" "}
                  <span>Lite</span>
                </h3>
              )}
              {exterior && intl.locale === "de" && (
                <h3 className="exterior-title">
                  <span>Standard</span>
                </h3>
              )}
              {!exterior && <h3>Standard</h3>}
              <hr />
              <ul>
                {exterior ? (
                  <>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.estateModel",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.listView",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.requestSupport",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.allDevices",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.touch4K",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.resources3D",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.integrationCrm",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.easyImplementation",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.compareFlats",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.balconyView",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.investmentArea",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.areaBuildingDetails",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.nightMode",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.localizations",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.video",
                      })}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.floorPlan",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.tour",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.dollhouse",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.model360",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.tripleStyles",
                      })}
                    </li>
                    <li className="hide">
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.developerLogo",
                      })}
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div
              className={`plan plan__premium ${
                exterior ? "plan__exterior" : ""
              } ${activeIndex === 2 ? "active" : ""}`}
            >
              {exterior && intl.locale !== "de" && (
                <h3 className="exterior-title">
                  {intl.formatMessage({
                    id: "exteriorPage.smartModelPricingListTitle.smartModel",
                  })}{" "}
                  <span>Standard</span>
                </h3>
              )}
              {exterior && intl.locale === "de" && (
                <h3 className="exterior-title">
                  <span>Premium</span>
                </h3>
              )}
              {!exterior && <h3>Premium</h3>}
              <hr />
              <ul>
                {exterior ? (
                  <>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.estateModel",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.listView",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.requestSupport",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.allDevices",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.touch4K",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.resources3D",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.integrationCrm",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.easyImplementation",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.compareFlats",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.balconyView",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.investmentArea",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.areaBuildingDetails",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.nightMode",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.localizations",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "exteriorPage.smartModelPricingList.video",
                      })}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.floorPlan",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.tour",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.dollhouse",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.model360",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.tripleStyles",
                      })}
                    </li>
                    <li>
                      <IoCheckmarkSharp />
                      {intl.formatMessage({
                        id: "interiorPage.flexibleSubscriptionPlan.developerLogo",
                      })}
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        {isMobile && (
          <Link
            to="contact"
            offset={-20}
            smooth={true}
            duration={800}
            className="btn_3destate btn__ghost-btn mt-2"
          >
            {intl.formatMessage({
              id: "interiorPage.flexibleSubscriptionPlanButton",
            })}
          </Link>
        )}
      </div>
    </section>
  );
};

export default FlexibleSubscriptionSection;
