import React from "react";
import InteriorFloorplan from "./InteriorFloorplan";
import InteriorModel360 from "./InteriorModel360";
import InteriorTour from "./InteriorTour";
import './ManyPresentationFormsSection.scss'

const ManyPresentationFormsSection = ({intl}) => {
  return (
    <>
      <section className="_3de-many-presentation-form-section">
        <div className="_3de-container-wrapper">
          <h2 className="_3de-many-presentation-form-section__title">
            {intl.formatMessage({ id: 'interiorPage.presentationFormTitle' })}
          </h2>
          <InteriorModel360 intl={intl} />
          <InteriorTour intl={intl} />
          <InteriorFloorplan intl={intl} />
        </div>
      </section>
    </>
  )
}

export default ManyPresentationFormsSection;
