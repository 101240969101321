import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout";
import Seo from "../components/Seo";

import HeroExteriorSection from "../components/Exterior/HeroExteriorSection";
import ProductRange from "../components/Exterior/ProductRange";
import ComplexPresentation from "../components/Exterior/ComplexPresentation";
import SmartModelPossibility from "../components/Exterior/SmartModelPossibility";
import FlexibleSubscriptionSection from "../components/Interior/FlexibleSubscriptionSection";
import OrderSmartModel from "../components/Exterior/OrderSmartModel";
import OurClientsSection from "../components/Reusable/OurClientsSections";
import OurPartnerSection from "../components/Reusable/OurPartnersSection";
import SmartGallery from "../components/Exterior/SmartGallery";
import ContactSection from "../components/Home/ContactSection";
import Footer from "../components/Reusable/Footer";

const ExteriorPage = () => {
  const intl = useIntl();

  return (
    <>
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({ id: "headExterior.title" })}
        description={intl.formatMessage({ id: "headExterior.description" })}
        customLink
      />
      <Layout intl={intl}>
        <HeroExteriorSection intl={intl}/>
        <ProductRange intl={intl} />
        <ComplexPresentation intl={intl} />
        <SmartModelPossibility intl={intl} />
        <SmartGallery intl={intl} />
        <FlexibleSubscriptionSection intl={intl} exterior />
        <OrderSmartModel intl={intl} />
        <OurClientsSection intl={intl} />
        <OurPartnerSection intl={intl} />
        <ContactSection intl={intl} />
        <Footer intl={intl} />
      </Layout>
      </>
  )
}

export default ExteriorPage;
