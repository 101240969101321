import React, {useEffect, useState} from "react";
import FrameWrapper from "../Reusable/FrameWrapper";
import {Link} from "react-scroll";
import "./InteriorTour.scss";
import Example1 from "../../images/components/interior/tour-example1.png";
import Example2 from "../../images/components/interior/tour-example2.png";
import Example3 from "../../images/components/interior/tour-example3.png";
import Example4 from "../../images/components/interior/tour-example4.png";
import Example5 from "../../images/components/interior/tour-example5.png";
import Example6 from "../../images/components/interior/tour-example6.png";
import {handlerTracking} from "../../helpers/ga";

const InteriorTour = ({intl}) => {
  const [pickedExampleIndex, setPickedExampleIndex] = useState("1");
  const [pickedExampleType, setPickedExampleType] = useState("oslo");
  const [initialHide, setInitialHide] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      setIsMobile(true);
    }
  }, []);

  const handlerSeeExample = (index, type, example) => {
    handlerTracking(
      "Example Button",
      "Click",
      `Show example tour - ${example}`
    );

    if (isMobile) {
      return window.open(
        `https://tours.3destate.pl/wnetrza-mieszkan---wirtualny-spacer/${type}.3d?lang=en`,
        "_blank"
      );
    }

    setPickedExampleIndex(index);
    setPickedExampleType(type);
    setInitialHide(false);
  };

  return (
    <div className="_3de-tour-section__tour-content">
      <hr className="mobile-hr" />
      <div className="left-column">
        <FrameWrapper
          intl={intl}
          src={`https://tours.3destate.pl/wnetrza-mieszkan---wirtualny-spacer/${pickedExampleType}.3d?lang=en`}
          title="tour-example"
          type="tour"
          initialHide={initialHide}
          interiorTour
        />
        <div className="other-examples">
          <p className="other-examples__text">
            {intl.formatMessage({id: "interiorPage.extra.seeOtherExample"})}
          </p>
          <div className="d-flex">
            {!isMobile && (
              <button
                type="button"
                className={`${pickedExampleIndex === "1" ? "active" : ""}`}
                onClick={() => handlerSeeExample("1", "oslo", "Example-1")}
              >
                <img src={Example1} alt="exmaple-1" />
                <p className="description">
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.title",
                  })}
                </p>
              </button>
            )}
            <button
              type="button"
              className={`${pickedExampleIndex === "2" ? "active" : ""}`}
              onClick={() => handlerSeeExample("2", "berlin", "Example-2")}
            >
              <img src={Example2} alt="example-2" />
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle5.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "3" ? "active" : ""}`}
              onClick={() => handlerSeeExample("3", "paris", "Example-3")}
            >
              <img src={Example3} alt="example-3" />
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle2.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "4" ? "active" : ""}`}
              onClick={() => handlerSeeExample("4", "amsterdam", "Example-4")}
            >
              <img src={Example4} alt="example-4" />
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle3.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "5" ? "active" : ""}`}
              onClick={() => handlerSeeExample("5", "london", "Example-5")}
            >
              <img src={Example5} alt="example-5" />
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle4.title",
                })}
              </p>
            </button>
            <button
              type="button"
              className={`${pickedExampleIndex === "6" ? "active" : ""}`}
              onClick={() => handlerSeeExample("6", "mediolan", "Example-6")}
            >
              <img src={Example6} alt="example-6" />
              <p className="description">
                {intl.formatMessage({
                  id: "interiorPage.styleRichnessStyle6.title",
                })}
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="right-column right-column__interior-tour">
        <div className="_3de-tour-section__tour-content-informations">
          <div className="left-tablet">
            <h3>
              {intl.formatMessage({
                id: "interiorPage.presentationFormTourTitle",
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: "interiorPage.presentationFormTourDescription",
              })}
            </p>
          </div>
          <div className="right-tablet">
            <ul>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormTourFeatures.feature1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormTourFeatures.feature2",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormTourFeatures.feature3",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormTourFeatures.feature4",
                })}
              </li>
            </ul>
            <Link
              to="contact"
              offset={-20}
              smooth={true}
              duration={800}
              className="btn_3destate"
              onClick={() => handlerTracking("CTA", "Click", "Ask about offer")}
            >
              {intl.formatMessage({id: "navigation.askAboutOfferButton"})}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteriorTour;
