import React from "react";
import './GlobalDeliveryNumbersSection.scss';
import BgMap from '../../images/components/home/map-comp.png'

const GlobalDeliveryNumbersSection = ({intl}) => {

  return (
    <section className="_3de-global-delivery-number-section">
      <div className="_3de-container-wrapper">
        <img loading="eager" className="_3de-container-wrapper__bg-image" src={BgMap} alt="background"/>
        <h2 className="_3de-global-delivery-number-section__title">{intl.formatMessage({ id: 'homePage.globalDeliveryTitle' })}</h2>
        <div className="_3de-global-delivery-number-section__in-numbers">
          <div className="sold-tours">
            <span>170 000</span>
            <p>{intl.formatMessage({ id: 'homePage.globalDeliveryFirstNumber' })}</p>
          </div>
          <div className="unique-users">
            <span>730 000</span>
            <p>{intl.formatMessage({ id: 'homePage.globalDeliverySecondNumber' })}</p>
          </div>
          <div className="monthly-visits">
            <span>210 000</span>
            <p>{intl.formatMessage({ id: 'homePage.globalDeliveryThirdNumber' })}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GlobalDeliveryNumbersSection;
