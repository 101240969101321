// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-funds-js": () => import("./../../../src/pages/funds.js" /* webpackChunkName: "component---src-pages-funds-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interactive-mockup-js": () => import("./../../../src/pages/interactive-mockup.js" /* webpackChunkName: "component---src-pages-interactive-mockup-js" */),
  "component---src-pages-interactive-smart-mockup-js": () => import("./../../../src/pages/interactive-smart-mockup.js" /* webpackChunkName: "component---src-pages-interactive-smart-mockup-js" */),
  "component---src-pages-interior-js": () => import("./../../../src/pages/interior.js" /* webpackChunkName: "component---src-pages-interior-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

