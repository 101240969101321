import React from "react";
import {Link} from "gatsby-plugin-intl";
import "./Model360Section.scss";
import FrameWrapper from "../Reusable/FrameWrapper";
import {handlerTracking} from "../../helpers/ga";

const Model360Section = ({intl}) => (
  <section className="_3de-model-360-section">
    <div className="_3de-container-wrapper">
      {/* <h2 className="_3de-model-360-section__title">
        {intl.formatMessage({id: "homePage.discoverTitle"})}
      </h2> */}
      <div className="_3de-model-360-section__model360-content">
        <div className="left-column">
          <div className="_3de-model-360-section__model360-content-informations">
            <div className="left-tablet">
              <h3>
                {intl.formatMessage({id: "homePage.discoverModel360Title"})}
              </h3>
              <p>
                {intl.formatMessage({
                  id: "homePage.discoverModel360Description",
                })}
              </p>
            </div>
            <div className="right-tablet">
              <ul>
                <li>
                  {intl.formatMessage({
                    id: "homePage.discoverModel360ListFirst",
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "homePage.discoverModel360ListSecond",
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "homePage.discoverModel360ListThird",
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    id: "homePage.discoverModel360ListForth",
                  })}
                </li>
              </ul>
              <Link
                to="/interior"
                className="btn_3destate z-2"
                onClick={() =>
                  handlerTracking(
                    "About Product Button",
                    "Click",
                    "Open Interior #Model360"
                  )
                }
              >
                {intl.formatMessage({id: "homePage.extra.knowProductButton"})}
              </Link>
            </div>
          </div>
        </div>
        <div className="right-column">
          <FrameWrapper
            intl={intl}
            src={`https://360.3destate.pl/przyklady-model-360/1?lang=en&isTour=true`}
            title="model-360-example"
            type="ttdh"
            initialHide
          />
        </div>
      </div>
    </div>
    <hr className="mobile-hr" />
  </section>
);

export default Model360Section;
