import React from "react";
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/Layout";

const BlogPage = () => {
  const intl = useIntl();

  return (
    <Layout intl={intl}>
      <h2>{intl.formatMessage({ id: 'blogPageTitle' })}</h2>
    </Layout>
  )
}

export default BlogPage;
