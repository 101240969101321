import React, {useEffect, useState} from "react";
import "./ContactSection.scss";
import {FaFacebookF, FaLinkedinIn, FaInstagram} from "react-icons/fa";
import {IoCheckmarkSharp, IoClose} from "react-icons/io5";
import {handlerTracking} from "../../helpers/ga";

const ContactSection = ({intl}) => {
  const [formName, setFormName] = useState("");
  const [formNameValidation, setFormNameValidation] = useState("");
  const [formCompanyName, setFormCompanyName] = useState("");
  const [formCompanyNameValidation, setFormCompanyNameValidation] =
    useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formEmailValidation, setFormEmailValidation] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formPhoneValidation, setFormPhoneValidation] = useState("");
  const [formComment, setFormComment] = useState("");
  const [formInvestment, setFormInvestment] = useState("");
  const [formApartmentsNumber, setFormApartmentsNumber] = useState("");
  const [firstRodo, setFirstRodo] = useState(false);
  const [firstRodoValidation, setFirstRodoValidation] = useState("");
  const [secondRodo, setSecondRodo] = useState(false);
  const [sentData, setSentData] = useState(false);
  const [error, setError] = useState("");
  const [showAdminAgreement, setShowAdminAgreement] = useState(false);
  const [seenAdminAgreement, setSeenAdminAgreement] = useState(false);
  const [sendingData, setSendingData] = useState(false);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("closeAgreement"))) {
      setSeenAdminAgreement(true);
    }
  }, []);

  const handlerUpdateFormName = (e) => {
    const {value} = e.target;

    setFormName(value);

    if (!value.length > 0) {
      setFormNameValidation("");
    }
    if (value.length > 0 && value.length < 3) {
      setFormNameValidation("error");
    }
    if (value.length >= 3) {
      setFormNameValidation("success");
      setError("");
    }
  };

  const handlerUpdateFormCompanyName = (e) => {
    const {value} = e.target;

    setFormCompanyName(value);

    if (!value.length > 0) {
      setFormCompanyNameValidation("");
    }
    if (value.length > 0 && value.length < 2) {
      setFormCompanyNameValidation("error");
    }
    if (value.length >= 2) {
      setFormCompanyNameValidation("success");
      setError("");
    }
  };

  const handlerUpdateFormEmail = (e) => {
    const {value} = e.target;
    const atLeastOneCharacter = value.length > 0;
    const emailMark = value.indexOf("@") !== -1;
    const fromEmailMark = emailMark ? value.substring(value.indexOf("@")) : "";
    const characterAfterEmailMark = emailMark
      ? value.substring(value.indexOf("@"), value.length).length
      : 0;
    const emailDot = fromEmailMark.indexOf(".") !== -1;
    const dotAfterEmailMark =
      emailMark && emailDot
        ? fromEmailMark.substring(
            fromEmailMark.indexOf("."),
            fromEmailMark.length
          ).length
        : 0;

    setFormEmail(value);

    if (!atLeastOneCharacter) {
      setFormEmailValidation("");
    }
    if (atLeastOneCharacter) {
      setFormEmailValidation("error");
    }
    if (
      atLeastOneCharacter &&
      emailMark &&
      characterAfterEmailMark > 1 &&
      emailDot &&
      dotAfterEmailMark > 2
    ) {
      setFormEmailValidation("success");
      setError("");
    }
  };

  const handlerUpdateFormPhone = (e) => {
    const {value} = e.target;
    const regExp = /[a-zA-Z]/g;
    const convertedToNumbers = value.replace(/ /g, "").replace(/-/g, "");
    const containsLetter = regExp.test(value);

    setFormPhone(value);

    if (!value.length > 0) {
      setFormPhoneValidation("");
    }
    if ((value.length > 0 && convertedToNumbers.length < 9) || containsLetter) {
      setFormPhoneValidation("error");
    }
    if (value.length > 0 && convertedToNumbers.length >= 9 && !containsLetter) {
      setFormPhoneValidation("success");
      setError("");
    }
  };
  const handlerUpdateFormInvestment = (e) => setFormInvestment(e.target.value);
  const handlerUpdateFormApartmentsNumber = (e) =>
    setFormApartmentsNumber(e.target.value);
  const handlerUpdateFormComment = (e) => setFormComment(e.target.value);

  const handlerCheckboxAgreement = (e, type) => {
    const {checked} = e.target;

    if (type === "first-rodo") {
      setFirstRodo(checked);
      setFirstRodoValidation("");
      if (error.includes("Zgoda")) {
        setError("");
      }
    }

    if (type === "second-rodo") {
      setSecondRodo(checked);
    }
  };

  const handlerFormSubmit = (e) => {
    e.preventDefault();
    if (sendingData) return;

    if (formNameValidation !== "success") {
      setFormNameValidation("error");
      return setError(
        `${intl.formatMessage({id: "homePage.contactValidation.name"})}`
      );
    }
    if (formCompanyNameValidation !== "success") {
      setFormCompanyNameValidation("error");
      return setError(
        `${intl.formatMessage({id: "homePage.contactValidation.company"})}`
      );
    }
    if (formEmailValidation !== "success") {
      setFormEmailValidation("error");
      return setError(
        `${intl.formatMessage({id: "homePage.contactValidation.email"})}`
      );
    }
    if (formPhoneValidation !== "success") {
      setFormPhoneValidation("error");
      return setError(
        `${intl.formatMessage({id: "homePage.contactValidation.phone"})}`
      );
    }

    // if (!firstRodo) {
    //   setError(
    //     `${intl.formatMessage({id: "homePage.contactValidation.agreement"})}`
    //   );
    //   return setFirstRodoValidation("error");
    // }

    // if (sentData) {
    //   return setError(
    //     `${intl.formatMessage({id: "homePage.contactValidation.alreadySent"})}`
    //   );
    // }

    setSendingData(true);
    const lang = intl.locale.toUpperCase();

    const data = {
      name: formName,
      companyName: formCompanyName,
      email: formEmail,
      phone: formPhone,
      investmentName: formInvestment,
      flatsCount: formApartmentsNumber,
      comment: formComment,
      //agreement: secondRodo,
      language: lang,
    };

    handlerTracking("Contact Form", "Click", `Sent Email`);
    fetch("/sender.php", {
      //HERE_PASTE_YOUR_EMAIL_ENDPOINT
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (response.status >= 500) {
          setSendingData(false);
          setError(`${response.statusText} - info@3destateusa.com`); // error with contact mail
          return console.error("ERROR:", response.statusText);
        }

        if (!response.ok) {
          const data = await response.json();
          const error = data.errorMessage || response.statusText;
          return Promise.reject(error);
        }

        setSendingData(false);
        setSentData(true);
      })
      .catch((error) => {
        setSendingData(false);
        setError(`${error} - info@3destateusa.com`); // error with contact mail
        console.error("Error cough:", error);
      });

    const hideThankYouInfo = setTimeout(() => {
      setError("");
      setSentData(false);
      clearTimeout(hideThankYouInfo);
    }, 8000);
  };

  const handlerCloseAdminAgreements = () => {
    setShowAdminAgreement(false);
    sessionStorage.setItem("closeAgreement", "true");
  };

  const handlerSeenAgreements = () => {
    if (seenAdminAgreement) {
      return;
    }
    setShowAdminAgreement(true);
    setSeenAdminAgreement(true);
  };

  return (
    <section id="contact" className="_3de-contact-section">
      {showAdminAgreement}
      <div className="_3de-container-wrapper">
        <h2 className="_3de-container-wrapper__title">
          {intl.formatMessage({id: "homePage.contactTitle"})}
        </h2>
      </div>
      <div className="_3de-contact-section__contact-content">
        <div className="left-column">
          <h3 className="sub-title">
            {intl.formatMessage({id: "homePage.contactDescription"})}
          </h3>
          <p className="description">
            {/* {intl.formatMessage({id: "homePage.contactSubDescription"})} */}
          </p>
          <form onSubmit={handlerFormSubmit}>
            <fieldset>
              <label htmlFor="name" className="form-label">
                <input
                  className={`${formNameValidation}`}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "homePage.contactNamePlaceholder",
                  })}
                  value={formName}
                  onFocus={handlerSeenAgreements}
                  onChange={handlerUpdateFormName}
                />
              </label>
              <label htmlFor="companyName" className="form-label">
                <input
                  className={`${formCompanyNameValidation}`}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "homePage.contactCompanyPlaceholder",
                  })}
                  value={formCompanyName}
                  onFocus={handlerSeenAgreements}
                  onChange={handlerUpdateFormCompanyName}
                />
              </label>
              <label htmlFor="address" className="form-label">
                <input
                  className={`${formEmailValidation}`}
                  type="email"
                  placeholder={intl.formatMessage({
                    id: "homePage.contactEmailPlaceholder",
                  })}
                  value={formEmail}
                  onChange={handlerUpdateFormEmail}
                  onFocus={handlerSeenAgreements}
                />
              </label>
              <label htmlFor="phone" className="form-label">
                <input
                  className={`${formPhoneValidation}`}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "homePage.contactPhonePlaceholder",
                  })}
                  value={formPhone}
                  onFocus={handlerSeenAgreements}
                  onChange={handlerUpdateFormPhone}
                />
              </label>
              <label htmlFor="phone" className="form-label">
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "homePage.contactInvestmentPlaceholder",
                  })}
                  value={formInvestment}
                  onChange={handlerUpdateFormInvestment}
                />
              </label>
              <label htmlFor="phone" className="form-label">
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "homePage.contactFlatNumberPlaceholder",
                  })}
                  value={formApartmentsNumber}
                  onChange={handlerUpdateFormApartmentsNumber}
                />
              </label>
              <textarea
                name="message"
                rows="7"
                placeholder={intl.formatMessage({
                  id: "homePage.contactCommentPlaceholder",
                })}
                value={formComment}
                onChange={handlerUpdateFormComment}
              />
              {/* <div className={`agreements-wrapper ${firstRodoValidation}`}>
                <input
                  id="first-rodo"
                  type="checkbox"
                  onChange={(e) => handlerCheckboxAgreement(e, "first-rodo")}
                />
                <label
                  className={firstRodo ? "active" : ""}
                  htmlFor="first-rodo"
                >
                  {intl.formatMessage({id: "homePage.contactAgreement1"})}
                </label>
              </div>
              <div className="agreements-wrapper">
                <input
                  id="second-rodo"
                  type="checkbox"
                  onChange={(e) => handlerCheckboxAgreement(e, "second-rodo")}
                />
                <label
                  className={secondRodo ? "active" : ""}
                  style={{marginBottom: "0"}}
                  htmlFor="second-rodo"
                >
                  {intl.formatMessage({id: "homePage.contactAgreement2"})}
                </label>
              </div> */}
              <div className="send-button-wrapper">
                <input
                  className="submit-button"
                  type="submit"
                  value={intl.formatMessage({id: "homePage.contactSendButton"})}
                />
                {sendingData && <div className="loader" />}
              </div>
            </fieldset>
          </form>
          {error && error.length > 0 && (
            <div className={`error-wrapper ${sentData ? "sent-data" : ""}`}>
              <h3>{error}</h3>
              <div className="circle-wrapper">
                <IoClose />
              </div>
            </div>
          )}
          <div className={`thank-you-wrapper ${sentData ? "active" : ""}`}>
            <h3>{intl.formatMessage({id: "homePage.contactThankYou"})}</h3>
            <div className="circle-wrapper">
              <IoCheckmarkSharp />
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="table-left">
            <h3 className="sub-title">
              {intl.formatMessage({id: "homePage.contactData"})}
            </h3>
            <div className="contact-data">
              {intl.locale === "de" ? (
                <>
                  <a
                    href="tel:0048512012333"
                    onClick={() =>
                      handlerTracking(
                        "Footer Link",
                        "Click",
                        `Open Contact Phone Number`
                      )
                    }
                  >
                    0048 512 012 333
                  </a>
                  <a
                    href="mailto:nicole@3destate.pl"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      handlerTracking(
                        "Footer Link",
                        "Click",
                        `Open Contact Email Address`
                      )
                    }
                  >
                    nicole@3destate.pl
                  </a>
                </>
              ) : (
                <>
                  <a
                    href="tel:9179429815"
                    onClick={() =>
                      handlerTracking(
                        "Footer Link",
                        "Click",
                        `Open Contact Phone Number`
                      )
                    }
                  >
                    (917) 942-9815
                  </a>
                  <a
                    href="mailto:info@3destateusa.com"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() =>
                      handlerTracking(
                        "Footer Link",
                        "Click",
                        `Open Contact Email Address`
                      )
                    }
                  >
                    info@3destateusa.com
                  </a>
                </>
              )}
            </div>
            <p>1225 Franklin Avenue,</p>
            <p>Garden City, New York 11530</p>
          </div>
          <div className="tablet-right">
            <h3 className="sub-title">
              {/* {intl.formatMessage({id: "homePage.contactSocialTitle"})} */}
            </h3>
            <p>
              {intl.formatMessage({id: "homePage.contactSocialDescription"})}
            </p>
            <ul className="social-media-list mt-1">
              <li>
                <a
                  className="facebook-icon"
                  href="https://www.facebook.com/permalink.php?story_fbid=963557464324846&id=578109989536264"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="show facebook"
                  onClick={() =>
                    handlerTracking("Footer Link", "Click", `Open Facebook`)
                  }
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  className="linkedin-icon"
                  href="https://www.linkedin.com/company/3destate-usa/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="show linkedin"
                  onClick={() =>
                    handlerTracking("Footer Link", "Click", `Open Linkedin`)
                  }
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li>
                <a
                  className="linkedin-icon"
                  href="https://www.instagram.com/3destate_usa/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="show instagram"
                  onClick={() =>
                    handlerTracking("Footer Link", "Click", `Open Instagram`)
                  }
                >
                  <FaInstagram />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
