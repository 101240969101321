import React from "react";
import {Link} from "react-scroll";
import "./AskAboutButton.scss";
import {handlerTracking} from "../../helpers/ga";

const AskAboutButton = ({intl}) => (
  <Link
    to="contact"
    offset={-20}
    smooth={true}
    duration={800}
    className={`ask-about-offer lang-${intl.locale}`}
    type="button"
    onClick={() => handlerTracking("CTA", "Click", "Ask about offer")}
  >
    <span>{intl.formatMessage({id: "navigation.askAboutOfferButton"})}</span>
    <div className={`liquid lang-${intl.locale}`} />
  </Link>
);

export default AskAboutButton;
