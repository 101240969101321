import React, {useEffect, useState} from "react";
import {changeLocale, Link} from "gatsby-plugin-intl";
import Logo3Destate from "../images/logo-3destate.svg";
import AskAboutButton from "./utility/AskAboutButton";
import {FaFacebookF, FaLinkedinIn, FaInstagram} from "react-icons/fa";
import {handlerTracking} from "../helpers/ga";

const NavDesktop = ({intl, funds}) => {
  const [language] = useState(intl.locale === "fr" ? "en" : intl.locale);
  const [selectLanguage, setSelectLanguage] = useState(false);
  const [initialPathName, setInitialPathName] = useState("");

  const handlerChangeLanguage = (lang) => {
    changeLocale(lang);
    handlerTracking("Language Button", "Click", `Changed language to ${lang}`);
  };

  const handlerSelectLanguage = () => {
    setSelectLanguage(!selectLanguage);
    if (!selectLanguage) {
      handlerTracking("Language Button", "Click", `Open language list`);
    }
  };

  useEffect(() => {
    const pathName = window.location.pathname;

    if (
      pathName.includes("interactive-mockup") ||
      pathName.includes("interactive-smart-mockup")
    ) {
      setInitialPathName("exterior");
    } else if (pathName.includes("interior")) {
      setInitialPathName("interior");
    } else {
      setInitialPathName("home");
    }
  }, []);

  return (
    <div className="_3de-navigation">
      <ul className="_3de-navigation__list">
        <li className="_3de-navigation__list-item">
          <Link
            to="/"
            className="_3de-navigation__list-item--logo"
            onClick={() =>
              handlerTracking(
                "Navigation Link",
                "Click",
                "Mobile - Go to home (LOGO)"
              )
            }
          >
            <img src={Logo3Destate} alt="3destate-logo" />
          </Link>
        </li>
        <li className="_3de-navigation__list-item">
          <Link
            className={`link ${
              initialPathName === "home" && !funds ? "active" : ""
            }`}
            to="/"
            onClick={() =>
              handlerTracking("Navigation Link", "Click", "Open home page")
            }
            activeClassName="active"
          >
            {intl.formatMessage({id: "navigation.homePage"})}
          </Link>
        </li>
        <li className="_3de-navigation__list-item">
          <Link
            className={`link ${initialPathName === "interior" ? "active" : ""}`}
            to="/interior"
            onClick={() =>
              handlerTracking("Navigation Link", "Click", "Open interior page")
            }
            activeClassName="active"
          >
            {intl.formatMessage({id: "navigation.interiorPage"})}
          </Link>
        </li>
        <li className="_3de-navigation__list-item">
          <Link
            className={`link ${initialPathName === "exterior" ? "active" : ""}`}
            to="/interactive-mockup"
            onClick={() =>
              handlerTracking("Navigation Link", "Click", "Open exterior page")
            }
            activeClassName="active"
          >
            {intl.formatMessage({id: "navigation.exteriorPage"})}
          </Link>
        </li>
      </ul>
      <ul className="_3de-navigation__list">
        <li className="_3de-navigation__list-item">
          <AskAboutButton intl={intl} />
        </li>
        <li className="_3de-navigation__list-item">
          <a
            href="https://www.facebook.com/permalink.php?story_fbid=963557464324846&id=578109989536264"
            className="social-media link fb"
            target="_blank"
            onClick={() =>
              handlerTracking("Navigation Link", "Click", "Open Facebook")
            }
            aria-label="show facebook"
            rel="noreferrer"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.linkedin.com/company/3destate-usa/"
            className="social-media link linkedin"
            target="_blank"
            aria-label="show linkedin"
            onClick={() =>
              handlerTracking("Navigation Link", "Click", "Open Linkedin")
            }
            rel="noreferrer"
          >
            <FaLinkedinIn />
          </a>
          <a
            href="https://www.instagram.com/3destate_usa/"
            className="social-media link linkedin"
            target="_blank"
            aria-label="show instagram"
            onClick={() =>
              handlerTracking("Navigation Link", "Click", "Open Instagram")
            }
            rel="noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NavDesktop;
