import React, {useEffect, useState} from "react";
import "./StyleOptionRichnessSection.scss";
import StyleParisImage from "../../images/components/interior/styles/paris-mobile.png";
import StyleOsloImage from "../../images/components/interior/styles/oslo-mobile.png";
import StyleAmsterdamImage from "../../images/components/interior/styles/amsterdam-mobile.png";
import StyleBerlinImage from "../../images/components/interior/styles/berlin-mobile.png";
import StyleLondonImage from "../../images/components/interior/styles/london-mobile.png";
import StyleMilanImage from "../../images/components/interior/styles/milan-mobile.png";
import {handlerTracking} from "../../helpers/ga";

const StyleOptionRichnessSection = ({isMobile, intl}) => {
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState("");
  const [activeStyle, setActiveStyle] = useState("");
  const [activeStyleDescription, setActiveStyleDescription] = useState("");
  const [styleImageDelay, setStyleImageDelay] = useState(false);
  const [styleActiveStyleUrl, setActiveStyleUrl] = useState("");

  useEffect(() => {
    const delay = setTimeout(() => {
      setStyleImageDelay(true);
      clearTimeout(delay);
    }, 400);
  }, []);

  const selectActiveStyle = (e, index, style, description) => {
    const {url} = e.currentTarget.dataset;

    if (e.cancelable) {
      e.preventDefault();
    }

    e.target.addEventListener("touchstart", (ev) => {
      ev.preventDefault();
    });

    if (!active) {
      handlerTracking(
        "Styles Example Button",
        "Click",
        `Preview Style - ${style}`
      );
    }

    setActiveStyleUrl(url);

    setActive(!active);
    setActiveIndex(index);
    setActiveStyle(style);
    setActiveStyleDescription(description);
  };

  const showDescription = (e) => {
    if (!active && !e.currentTarget.classList.contains("hovered")) {
      e.currentTarget.classList.add("hovered");
    }
  };
  const hideDescription = (e) => {
    if (!active) {
      e.currentTarget.classList.remove("hovered");
    }
  };

  return (
    <section className="_3de-style-option-richness-section">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-style-option-richness-section__title">
          {intl.formatMessage({id: "interiorPage.styleRichnessTitle"})}
        </h2>
        <h3 className="_3de-style-option-richness-section__description">
          {intl.formatMessage({id: "interiorPage.styleRichnessDescription"})}
        </h3>
        {isMobile === false && styleImageDelay && (
          <div className="_3de-style-option-richness-section__content">
            {active && activeStyle && (
              <div className="style-badge">
                <h4 className="special">{activeStyle}</h4>
                <p className="special-description">{activeStyleDescription}</p>
                <a
                  className="special-link"
                  href={styleActiveStyleUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessPreviewButton",
                  })}
                </a>
              </div>
            )}
            <div
              className={`style-paris style ${
                active && activeIndex === "0" ? "active" : ""
              } ${activeIndex === "0" ? "z-index-2" : ""}`}
              onMouseUp={(e) =>
                selectActiveStyle(
                  e,
                  "0",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.description",
                  })}`
                )
              }
              onTouchEnd={(e) =>
                selectActiveStyle(
                  e,
                  "0",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.description",
                  })}`
                )
              }
              onKeyPress={(e) =>
                selectActiveStyle(
                  e,
                  "0",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.description",
                  })}`
                )
              }
              role="button"
              tabIndex="0"
              data-url={`https://tours.3destate.pl/website/paris.3d?lang=en`}
              onMouseMove={(e) => showDescription(e)}
              onMouseEnter={(e) => showDescription(e)}
              onMouseLeave={(e) => hideDescription(e)}
            >
              <div className="style-description">
                <h3>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.title",
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle2.description",
                  })}
                </p>
              </div>
              <div className="image" />
            </div>
            <div
              className={`style-berlin style ${
                active && activeIndex === "1" ? "active" : ""
              } ${activeIndex === "1" ? "z-index-2" : ""}`}
              onMouseUp={(e) =>
                selectActiveStyle(
                  e,
                  "1",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.description",
                  })}`
                )
              }
              onTouchEnd={(e) =>
                selectActiveStyle(
                  e,
                  "1",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.description",
                  })}`
                )
              }
              onKeyPress={(e) =>
                selectActiveStyle(
                  e,
                  "1",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.description",
                  })}`
                )
              }
              role="button"
              tabIndex="0"
              data-url={`https://tours.3destate.pl/website/berlin.3d?lang=en`}
              onMouseMove={(e) => showDescription(e)}
              onMouseEnter={(e) => showDescription(e)}
              onMouseLeave={(e) => hideDescription(e)}
            >
              <div className="style-description">
                <h3>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.title",
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle5.description",
                  })}
                </p>
              </div>
              <div className="image" />
            </div>
            <div
              className={`style-london style ${
                active && activeIndex === "2" ? "active" : ""
              } ${activeIndex === "2" ? "z-index-2" : ""}`}
              onMouseUp={(e) =>
                selectActiveStyle(
                  e,
                  "2",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.description",
                  })}`
                )
              }
              onTouchEnd={(e) =>
                selectActiveStyle(
                  e,
                  "2",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.description",
                  })}`
                )
              }
              onKeyPress={(e) =>
                selectActiveStyle(
                  e,
                  "2",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.description",
                  })}`
                )
              }
              role="button"
              tabIndex="0"
              data-url={`https://tours.3destate.pl/website/londyn.3d?lang=en`}
              onMouseMove={(e) => showDescription(e)}
              onMouseEnter={(e) => showDescription(e)}
              onMouseLeave={(e) => hideDescription(e)}
            >
              <div className="style-description">
                <h3>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.title",
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle4.description",
                  })}
                </p>
              </div>
              <div className="image" />
            </div>
            <div
              className={`style-oslo style ${
                active && activeIndex === "3" ? "active" : ""
              } ${activeIndex === "3" ? "z-index-2" : ""}`}
              onMouseUp={(e) =>
                selectActiveStyle(
                  e,
                  "3",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.description",
                  })}`
                )
              }
              onTouchEnd={(e) =>
                selectActiveStyle(
                  e,
                  "3",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.description",
                  })}`
                )
              }
              onKeyPress={(e) =>
                selectActiveStyle(
                  e,
                  "3",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.description",
                  })}`
                )
              }
              role="button"
              tabIndex="0"
              data-url={`https://tours.3destate.pl/website/oslo.3d?lang=en`}
              onMouseMove={(e) => showDescription(e)}
              onMouseEnter={(e) => showDescription(e)}
              onMouseLeave={(e) => hideDescription(e)}
            >
              <div className="style-description">
                <h3>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.title",
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle1.description",
                  })}
                </p>
              </div>
              <div className="image" />
            </div>
            <div
              className={`style-amsterdam style ${
                active && activeIndex === "4" ? "active" : ""
              } ${activeIndex === "4" ? "z-index-2" : ""}`}
              onMouseUp={(e) =>
                selectActiveStyle(
                  e,
                  "4",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.description",
                  })}`
                )
              }
              onTouchEnd={(e) =>
                selectActiveStyle(
                  e,
                  "4",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.description",
                  })}`
                )
              }
              onKeyPress={(e) =>
                selectActiveStyle(
                  e,
                  "4",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.description",
                  })}`
                )
              }
              role="button"
              tabIndex="0"
              data-url={`https://tours.3destate.pl/website/amsterdam.3d?lang=en`}
              onMouseMove={(e) => showDescription(e)}
              onMouseEnter={(e) => showDescription(e)}
              onMouseLeave={(e) => hideDescription(e)}
            >
              <div className="style-description">
                <h3>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.title",
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle3.description",
                  })}
                </p>
              </div>
              <div className="image" />
            </div>
            <div
              className={`style-milan style ${
                active && activeIndex === "5" ? "active" : ""
              } ${activeIndex === "5" ? "z-index-2" : ""}`}
              onMouseUp={(e) =>
                selectActiveStyle(
                  e,
                  "5",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.description",
                  })}`
                )
              }
              onTouchEnd={(e) =>
                selectActiveStyle(
                  e,
                  "5",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.description",
                  })}`
                )
              }
              onKeyPress={(e) =>
                selectActiveStyle(
                  e,
                  "5",
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.title",
                  })}`,
                  `${intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.description",
                  })}`
                )
              }
              role="button"
              tabIndex="0"
              data-url={`https://tours.3destate.pl/wnetrza-mieszkan---wirtualny-spacer/mediolan.3d?lang=en`}
              onMouseMove={(e) => showDescription(e)}
              onMouseEnter={(e) => showDescription(e)}
              onMouseLeave={(e) => hideDescription(e)}
            >
              <div className="style-description">
                <h3>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.title",
                  })}
                </h3>
                <p>
                  {intl.formatMessage({
                    id: "interiorPage.styleRichnessStyle6.description",
                  })}
                </p>
              </div>
              <div className="image" />
            </div>
          </div>
        )}
        {isMobile && styleImageDelay && (
          <div className="_3de-style-option-richness-section__mobile-content">
            <div className="style-cards">
              <div className="style-cards__card">
                <img src={StyleParisImage} alt="style-paris" />
                <div className="style-cards__card-description">
                  <h3>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle2.title",
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle2.description",
                    })}
                  </p>
                </div>
              </div>
              <div className="style-cards__card">
                <img src={StyleBerlinImage} alt="style-berlin" />
                <div className="style-cards__card-description">
                  <h3>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle5.title",
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle5.description",
                    })}
                  </p>
                </div>
              </div>
              <div className="style-cards__card">
                <img src={StyleLondonImage} alt="style-london" />
                <div className="style-cards__card-description">
                  <h3>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle4.title",
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle4.description",
                    })}
                  </p>
                </div>
              </div>
              <div className="style-cards__card">
                <img src={StyleOsloImage} alt="style-amsterdam" />
                <div className="style-cards__card-description">
                  <h3>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle1.title",
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle1.description",
                    })}
                  </p>
                </div>
              </div>
              <div className="style-cards__card">
                <img src={StyleAmsterdamImage} alt="style-amsterdam" />
                <div className="style-cards__card-description">
                  <h3>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle3.title",
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle3.description",
                    })}
                  </p>
                </div>
              </div>
              <div className="style-cards__card">
                <img src={StyleMilanImage} alt="style-milan" />
                <div className="style-cards__card-description">
                  <h3>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle6.title",
                    })}
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: "interiorPage.styleRichnessStyle6.description",
                    })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default StyleOptionRichnessSection;
