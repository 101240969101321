import React from "react";
import "./InteriorFloorplan.scss";
import {Link} from "react-scroll";
import Floorplan2D from "../../images/components/interior/floorplan-2d.png";
import Floorplan3D from "../../images/components/interior/floorplan-3d.png";
import {handlerTracking} from "../../helpers/ga";

const InteriorFloorplan = ({intl}) => {
  return (
    <div className="_3de-model-360-section__model360-content floorplan">
      <hr className="mobile-hr" />
      <div className="left-column">
        <div className="_3de-model-360-section__model360-content-informations">
          <div className="left-tablet">
            <h3>
              {intl.formatMessage({
                id: "interiorPage.presentationFormFloorPlanTitle",
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: "interiorPage.presentationFormFloorPlanDescription",
              })}
            </p>
          </div>
          <div className="right-tablet">
            <ul>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormFloorPlanFeatures.feature1",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormFloorPlanFeatures.feature2",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormFloorPlanFeatures.feature3",
                })}
              </li>
              <li>
                {intl.formatMessage({
                  id: "interiorPage.presentationFormFloorPlanFeatures.feature4",
                })}
              </li>
            </ul>
            <Link
              to="contact"
              offset={-20}
              smooth={true}
              duration={800}
              className="btn_3destate"
              onClick={() => handlerTracking("CTA", "Click", "Ask about offer")}
            >
              {intl.formatMessage({id: "navigation.askAboutOfferButton"})}
            </Link>
          </div>
        </div>
      </div>
      <div className="right-column">
        <div className="compare-images">
          <div className="first-image">
            <img src={Floorplan2D} alt="floorplan-2d" />
            <h3>
              {intl.formatMessage({
                id: "interiorPage.presentationFormFloorPlanStandard",
              })}
            </h3>
          </div>
          <div className="second-image">
            <img src={Floorplan3D} alt="floorplan-3d" />
            <h3>
              {intl.formatMessage({
                id: "interiorPage.presentationFormFloorPlanNew",
              })}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteriorFloorplan;
