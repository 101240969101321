import React from "react";
import "./OurPartnersSection.scss";
import AzureLogo from "../../images/components/home/partners/microsoft-azure-logo.png";
import PZFDLogo from "../../images/components/home/partners/pzfd-logo.png";
import PLGBCLogo from "../../images/components/home/partners/plgbc-logo.png";
import ObidoLogo from "../../images/components/home/partners/obido_logo.png";
import OtodomLogo from "../../images/components/home/partners/otodom-logo.png";
import DecoroomLogo from "../../images/components/home/partners/decoroom-logo.png";
import PomeraniaLogo from "../../images/components/home/partners/pomerania-expo-horizontal.png";

const OurPartnerSection = ({intl}) => {
  return (
    <section className="_3de-our-partners-section mt-5">
      <div className="_3de-container-wrapper">
        <h2 className="_3de-container-wrapper__title">
          {intl.formatMessage({id: "homePage.ourPartnersTitle"})}
        </h2>
        <div className="cards">
          <div className="cards_img">
            <img src={AzureLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard1"})}</p>
          </div>
          <hr className="mobile-hr" />
          <div className="cards_img">
            <img src={PZFDLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard2"})}</p>
          </div>
          <hr className="mobile-hr" />
          <div className="cards_img">
            <img src={PLGBCLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard3"})}</p>
          </div>
          <hr className="mobile-hr" />
          <div className="cards_img obido">
            <img src={ObidoLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard4"})}</p>
          </div>
          <hr className="mobile-hr" />
          <div className="cards_img">
            <img className="custom-margin" src={OtodomLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard5"})}</p>
          </div>
          <hr className="mobile-hr" />
          <div className="cards_img">
            <img className="custom-margin" src={DecoroomLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard6"})}</p>
          </div>
          <hr className="mobile-hr" />
          <div className="cards_img">
            <img className="custom-margin" src={PomeraniaLogo} alt="logo" />
            <p>{intl.formatMessage({id: "homePage.ourPartnersCard7"})}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartnerSection;
